import React, { useEffect, useState } from 'react'
import { useSiteContext } from '../context/SiteContext';
import { Button, Col, Row, Label, Input } from 'reactstrap';
import db, { storage } from '../firebase';
import { deleteObject, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import toast from 'react-hot-toast';
import Loader from './Loader';
import { MdArrowBack, MdCloudUpload, MdCreate, MdDelete, MdOutlineUpdate, MdOutlineUpgrade } from 'react-icons/md'
import Posts from './Posts'



const BrandsDetail = () => {
    const { dogruAy } = useSiteContext()

    const [tph_, setTph_] = useState();
    const [imgURL_, setImgURL_] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [imageAsset, setImageAsset] = useState(null);
    const [msg, setMsg] = useState(null);
    const [fields, setFields] = useState(false);
    const [alertStatus, setAlertStatus] = useState("danger");
    const [productImgURL, setProductImgURL] = useState();
    const [logoModal, setLogoModal] = useState(false);
    const [brandUserDetail, setBrandUserDetail] = useState();
    const [selectedContent, setSelectedContent] = useState();
    const [profile, setProfile] = useState();

    useEffect(() => {
        db.collection("profile/").where("uid", "==", JSON.parse(localStorage.getItem("userIds"))?.uid).onSnapshot(snapshot => (
            setProfile(snapshot.docs.map(doc => (
                {
                    uid: doc.data().uid,
                    email: doc.data().email,
                    userLevel: doc.data().userLevel,
                    name: doc.data().name,
                    phone: doc.data().phone,
                    brandUserId: doc.data().brandUserId,
                    services: doc.data().services
                }
            )))
        ));
    }, [])

    useEffect(() => {
        if (profile) {
            db.collection("brands/").where("brandId", "==", profile && profile[0]?.brandUserId).onSnapshot(snapshot => (
                setBrandUserDetail(snapshot.docs.map(doc => (
                    {
                        id: doc.id,
                        brandName: doc.data().brandName,
                        brandId: doc.data().brandId,
                        contentPcs: doc.data().contentPcs,
                        serviceStart: doc.data().serviceStart,
                        serviceEnd: doc.data().serviceEnd,
                        keyWords: doc.data().keyWords,
                        imgURL: doc.data().imgURL, brandPrice: doc.data().brandPrice,
                        editorPrice: doc.data().editorPrice,
                        brandSummary: doc.data().brandSummary
                    }
                )
                ))
            ))
        }

    }, [profile]);

    const toggleHandle_ = () => {
        setTph_(current => !current)
    }


    const uploadLogoModal = () => {
        setLogoModal(current => !current);
    }

    const uploadLogo = () => {
        db.collection("brands/").doc(localStorage.getItem("brandId")).update(
            {
                imgURL: imgURL_ ? imgURL_ : ""
            }
        )
        setLogoModal(current => !current)
    }

    const deleteLogo = () => {
        deleteObject(ref(storage, brandUserDetail && brandUserDetail[0].imgURL));
        db.collection("brands/").doc(localStorage.getItem("brandId")).update(
            {
                imgURL: ""
            }
        )

    }

    const uploadImage = (e) => {
        setIsLoading(true);
        const imageFile = e.target.files[0];
        const storageRef = ref(storage, `logos/${localStorage.getItem("brandId")}`);
        const uploadTask = uploadBytesResumable(storageRef, imageFile);

        uploadTask.on('state_changed', (snapshot) => {
            const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        }, (error) => {
            console.log(error);
            setFields(true);
            setMsg('Error while uploading : Try Again🙇‍♂️');
            setAlertStatus('danger');
            setTimeout(() => {
                setFields(false);
                setIsLoading(false);
            }, 4000)
        }, () => {
            getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                setImageAsset(downloadURL);
                setProductImgURL(downloadURL);
                console.log(downloadURL)
                setIsLoading(false);
                setFields(true);
                setImgURL_(downloadURL)
                toast.success('Image uploaded succesfully😎');
                setAlertStatus("success");
                setTimeout(() => {
                    setFields(false);
                }, 4000);

            })
        });
    }

    const deleteImage = () => {
        setIsLoading(true);
        const deleteRef = ref(storage, imageAsset);
        deleteObject(deleteRef).then(() => {
            setImageAsset(null);
            setIsLoading(false);
            setFields(true);
            setMsg('Image deleted succesfully🚮🗑');
            setTimeout(() => {
                setFields(false);
            }, 4000);
        })
    }



    return (
        <>
            <div className="container-fluid pt-4 px-4">
                <div className="row g-4">
                    <div className="col-sm-6 col-xl-3">
                        <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                            <div className="ms-3">
                                <h6>{brandUserDetail && brandUserDetail[0].brandName}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-4 mb-5">
                    <div className="col-sm-6 col-xl-6">
                        <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                            <div className="ms-3">
                                <p className="mb-2">Marka Özeti</p>
                                <h6 className="mb-0"> {brandUserDetail && brandUserDetail[0]?.brandSummary} </h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-6">
                        <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                            <div className="ms-3">
                                <p className="mb-2">Marka Brief</p>
                                <h6 className="mb-0"> {brandUserDetail && brandUserDetail[0]?.keyWords} </h6>
                            </div>
                        </div>
                    </div>
                </div>
                {tph_ && <>
                    <Row>
                        <div className="bg-secondary text-left rounded p-4 my-5">

                            <MdArrowBack style={{ fontSize: 34, width: "5%", cursor: "pointer" }} onClick={toggleHandle_} />
                            <Row>
                                <Posts content={selectedContent} />
                            </Row>
                        </div>
                    </Row>
                </>}
            </div>
        </>
    )
}

export default BrandsDetail