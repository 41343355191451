import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast';
import { Col, Row, InputGroup, Input, Label, Button } from 'reactstrap';
import { nanoid } from 'nanoid'
import db from '../firebase';
import { useSiteContext } from '../context/SiteContext';
import { Link } from 'react-router-dom';
import BrandsDetail from './BrandsDetail';
import defaultLogo from '../images/butik-icerik-logo-beyaz.png'
import { MdLogin } from 'react-icons/md';
import { FaFacebook, FaInstagram, FaPinterest, FaTiktok, FaTwitter, FaYoutube } from 'react-icons/fa';


const Brands = () => {

    const { patSearch, setPatSearch } = useSiteContext();

    const [brands, setBrands] = useState([]);

    const [tph_, setTph_] = useState(false);
    const [tph, setTph] = useState(false);
    const [brandName, setBrandName] = useState()
    const [contentPcs, setContentPcs] = useState()
    const [keyWords, setKeyWords] = useState()
    const [serviceType, setServiceType] = useState()
    const [serviceStart, setServiceStart] = useState()
    const [serviceEnd, setServiceEnd] = useState()
    const [brandPrice, setBrandPrice] = useState()
    const [editorPrice, setEditorPrice] = useState()
    const [brandSummary, setBrandSummary] = useState()
    const [brandIdNew, setBrandIdNew] = useState();
    const [selectEditor, setSelectEditor] = useState();
    const [profileTitles, setProfileTitles] = useState();
    const [brandSector, setBrandSector] = useState();
    const [brandURL, setBrandURL] = useState("");
    const [brandInstagramURL, setBrandInstagramURL] = useState("");
    const [brandPinterestURL, setBrandPinterestURL] = useState("");
    const [brandYoutubeURL, setBrandYoutubeURL] = useState("");
    const [brandFacebookURL, setBrandFacebookURL] = useState("");
    const [brandTiktokURL, setBrandTiktokURL] = useState("");
    const [brandTwitterURL, setBrandTwitterURL] = useState("");
    const [profile, setProfile] = useState();

    const resetFromField = () => {
        setBrandName("");
        setContentPcs("");
        setKeyWords("");
        setServiceStart("");
        setServiceEnd("");
        setBrandPrice("");
        setEditorPrice("");
        setBrandSummary("");
    }

    useEffect(() => {
        db.collection("profile/").where("uid", "==", JSON.parse(localStorage.getItem("userIds"))?.uid).onSnapshot(snapshot => (
            setProfile(snapshot.docs.map(doc => (
                {
                    uid: doc.data().uid,
                    email: doc.data().email,
                    userLevel: doc.data().userLevel,
                    name: doc.data().name,
                    phone: doc.data().phone,
                    brandUserId: doc.data().brandUserId,
                    services: doc.data().services
                }
            )))
        ));
    })

    useEffect(() => {
        db.collection("brands").onSnapshot(snapshot => (
            setBrands(snapshot.docs.map(doc => (
                {
                    id: doc.id,
                    brandName: doc.data().brandName,
                    brandId: doc.data().brandId,
                    contentPcs: doc.data().contentPcs,
                    serviceType: doc.data().serviceType,
                    serviceStart: doc.data().serviceStart,
                    serviceEnd: doc.data().serviceEnd,
                    wordCount: doc.data().wordCount,
                    keyWords: doc.data().keyWords,
                    imgURL: doc.data().imgURL
                }
            )))
        ))
    }, []);

    useEffect(() => {
        db.collection("profile").doc("profile_" + JSON.parse(localStorage.getItem("userIds"))?.uid).collection("titles").onSnapshot(snapshot => (
            setProfileTitles(snapshot.docs.map(doc => (
                {
                    brandId: doc.data().brandId,
                }
            )))
        ))

    }, []);

    const removeBrandFromDb = (id) => {
        setBrandIdNew(id)
        setTph(current => !current)
    }

    const removeBrandFromDb_ = () => {
        db.collection("brands").doc(brandIdNew).delete();
        setTph(current => !current)
    }

    const toggleHandle_ = () => {
        setTph_(current => !current)
    }

    const openNewBrandPopup = () => {
        setTph_(current => !current)
    }

    const toggleHandle = () => {
        setTph(current => !current)
    }

    const addNewBrand = () => {
        const brandNanoId = nanoid();
        if (brandName === "" || brandName === undefined || brandName === null) {
            toast.error("Marka adını giriniz.");
            return;
        } else if (brandSummary <= 0 || brandSummary === undefined || brandSummary === null) {
            toast.error("Marka özetini giriniz.");
            return;
        } else if (brandSector <= 0 || brandSector === undefined || brandSector === null) {
            toast.error("Marka Sektörünü giriniz.");
            return;
        } else {
            setTph_(current => !current)
            db.collection("brands/").doc(brandNanoId).set({
                brandId: brandNanoId,
                brandName: brandName,
                brandSummary: brandSummary,
                brandSector: brandSector,
                brandURL: brandURL,
                brandInstagramURL: brandInstagramURL,
                brandFacebookURL: brandFacebookURL,
                brandYoutubeURL: brandYoutubeURL,
                brandPinterestURL: brandPinterestURL,
                brandTiktokURL: brandTiktokURL,
                brandTwitterURL: brandTwitterURL,
                imgURL: ''
            })
            resetFromField();
        }
    }
    return (
        <>
            <Row xs="1" className="search-bar mt-5">
                <Col lg="6" className='align-self-center'>
                    <InputGroup>
                        <Input
                            placeholder="Ara"
                            type="search"
                            value={patSearch.toLowerCase()}
                            autoFocus="autoFocus"
                            onChange={(e) => setPatSearch(e.target.value)}
                            className="border border-primary"
                        />
                    </InputGroup>
                </Col>
                {profile && profile[0].userLevel == "2" ? <Col lg="6" className='text-end'>
                    <button type="button" className="btn btn-primary m-2" onClick={openNewBrandPopup}>Yeni Marka <b>+</b></button>
                </Col> : <></>}

            </Row>
            {tph && <div className="popup-box">
                <div className="box">
                    <span className="close-icon" onClick={toggleHandle}>x</span>
                    <Row className="product-name"> Markayı silmek istediğinize emin misiniz?</Row>
                    <Row>
                        <Col>
                            <Button
                                color="success"
                                outline
                                className="float-end"
                                onClick={(e) => removeBrandFromDb_(brands.brandId)}
                            >
                                Sil
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                color="danger"
                                outline
                                onClick={toggleHandle}
                            >
                                İptal
                            </Button>
                        </Col>
                    </Row>

                </div>
            </div>}
            <Row>
                {profile && profile[0].userLevel === "2" ? brands.filter((brands) => brands.brandName.toLowerCase().includes(patSearch)).map((brands, index) => (
                    <Col key={index} lg="4" xl="4">
                        <Row className="bg-secondary rounded d-flex align-items-center justify-content-between p-4 mt-5">
                            <Col xl="8">
                                <img src={brands.imgURL == "" ? defaultLogo : brands.imgURL} width="auto" height="55" />
                            </Col>
                            <Col xl="2">
                                <Link to={"/brand-details-admin"}>
                                    <Button className=' btn-outline-success' onClick={() => localStorage.setItem("brandId", brands.brandId)} >
                                        <MdLogin />
                                    </Button>
                                </Link>
                            </Col>
                            {profile && profile[0].userLevel == "2" ?
                                <Col xl="2">
                                    <Button className='my-2' color='danger' onClick={() => removeBrandFromDb(brands.id)} >Sil</Button>
                                </Col> : <></>}
                            <Col xl="12">
                                <h6 className="mt-3">{brands.brandName}</h6>
                            </Col>
                        </Row>
                    </Col>
                )) : <></>}
                {/* {profile && profile[0].userLevel === "1" ? profileTitles && profileTitles.map(profileTitles => (brands.filter((brands) => brands.brandName.toLowerCase().includes(patSearch)).filter(brands => brands.brandId === (profileTitles && profileTitles.brandId)).map((brands, index) => (
                    <Col key={index} lg="4" xl="4">
                        <Row className="bg-secondary rounded d-flex align-items-center justify-content-between p-4 mt-5">
                            <Col xl="8">
                                <img src={brands.imgURL == "" ? defaultLogo : brands.imgURL} width="auto" height="55" />
                            </Col>
                            <Col xl="2">
                                <Link to={"/brand-details"}>
                                    <Button className=' btn-outline-success' onClick={() => localStorage.setItem("brandId", brands.brandId)} >
                                        <MdLogin />
                                    </Button>
                                </Link>
                            </Col>
                            {profile && profile[0].userLevel == "2" ?
                                <Col xl="2">
                                    <Button className='my-2' color='danger' onClick={() => removeBrandFromDb(brands.id)} >Sil</Button>
                                </Col> : <></>}
                            <Col xl="12">
                                <h6 className="mt-3">{brands.brandName}</h6>
                            </Col>
                        </Row>
                    </Col>
                ))
                )) : <></>} */}
            </Row>

            {tph_ && <div className="popup-box">
                <div className="box">
                    <span className="close-icon" onClick={toggleHandle_}>x</span>
                    <Row>
                        <Col xl="12" className='align-self-center'>
                        </Col>
                        <Col xl="12" className='align-self-center'>
                            <Row className='mb-3'>
                                <Label className='mb-0'>Marka Adı:</Label>
                                <Input type="text" value={brandName} onChange={(e) => setBrandName(e.target.value)}></Input>
                            </Row>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col xl="6" className='align-self-center'>
                            <Row className='mb-3'>

                                <Label className='mb-0'>İçerik Adedi:</Label>


                                <Input type="number" value={contentPcs} onChange={(e) => setContentPcs(e.target.value)}></Input>

                            </Row>
                        </Col>
                        <Col xl="6" className='align-self-center'>
                            <select className="form-select" aria-label="Default select example" onChange={(e) => setSelectEditor(e.target.value)}>
                                <option value={""} >Kullanıcı Seçiniz</option>
                                {brandUsers?.map((editor, index) => (
                                    <option value={editor.uid}>{editor.name}</option>
                                ))}
                            </select>
                        </Col>
                    </Row> */}
                    {/* <Row>
                        <Col xl="6" className='align-self-center'>

                            <Row className='mb-3'>
                                <Label className='mb-0'>İçerik Fiyatı (Marka):</Label>
                                <Input type="number" value={brandPrice} onChange={(e) => setBrandPrice(e.target.value)}></Input>
                            </Row>
                        </Col>
                        <Col xl="6" className='align-self-center'>
                            <Row className='mb-3'>
                                <Label className='mb-0'>İçerik Fiyatı (Editör):</Label>
                                <Input type="number" value={editorPrice} onChange={(e) => setEditorPrice(e.target.value)}></Input>
                            </Row>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col xl="12" className='align-self-center'>
                            <Row className='mb-3'>
                                <Label className='mb-0'>Marka Web Sitesi Linki:</Label>
                                <Input type="url" value={brandURL} onChange={(e) => setBrandURL(e.target.value)} />

                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="6" className='align-self-center'>
                            <Row className='mb-3'>
                                <Label className='mb-0'>  <FaInstagram className='mb-1' /> Marka Instagram Linki:</Label>
                                <Input type="url" value={brandInstagramURL} onChange={(e) => setBrandInstagramURL(e.target.value)} />

                            </Row>
                        </Col>
                        <Col xl="6" className='align-self-center'>
                            <Row className='mb-3'>
                                <Label className='mb-0'>  <FaFacebook className='mb-1' /> Marka Facebook Linki:</Label>
                                <Input type="url" value={brandFacebookURL} onChange={(e) => setBrandFacebookURL(e.target.value)} />

                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="6" className='align-self-center'>
                            <Row className='mb-3'>
                                <Label className='mb-0'>  <FaYoutube className='mb-1' /> Marka YouTube Linki:</Label>
                                <Input type="url" value={brandYoutubeURL} onChange={(e) => setBrandYoutubeURL(e.target.value)} />

                            </Row>
                        </Col>
                        <Col xl="6" className='align-self-center'>
                            <Row className='mb-3'>
                                <Label className='mb-0'>  <FaTwitter className='mb-1' /> Marka Twitter Linki:</Label>
                                <Input type="url" value={brandTwitterURL} onChange={(e) => setBrandTwitterURL(e.target.value)} />

                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="6" className='align-self-center'>
                            <Row className='mb-3'>
                                <Label className='mb-0'>  <FaPinterest className='mb-1' /> Marka Pinterest Linki:</Label>
                                <Input type="url" value={brandPinterestURL} onChange={(e) => setBrandPinterestURL(e.target.value)} />

                            </Row>
                        </Col>
                        <Col xl="6" className='align-self-center'>
                            <Row className='mb-3'>
                                <Label className='mb-0'>  <FaTiktok className='mb-1' /> Marka Tiktok Linki:</Label>
                                <Input type="url" value={brandTiktokURL} onChange={(e) => setBrandTiktokURL(e.target.value)} />

                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="12" className='align-self-center'>
                            <Row className='mb-3'>
                                <Label className='mb-0'>Marka Özeti:</Label>
                                <Input type="textarea" value={brandSummary} onChange={(e) => setBrandSummary(e.target.value)} />
                            </Row>
                        </Col>
                        <Col xl="12" className='align-self-center'>
                            <Row className='mb-3'>
                                <Label className='mb-0'>Marka Sektörü:</Label>
                                <Input type="textarea" value={brandSector} onChange={(e) => setBrandSector(e.target.value)} />
                            </Row>
                        </Col>
                        {/* <Col xl="6" className='align-self-center'>
                            <Row className='mb-3'>
                                <Label className='mb-0'>Anahtar Kelimeler:</Label>
                                <Input type="textarea" value={keyWords} onChange={(e) => setKeyWords(e.target.value)}></Input>
                            </Row>
                        </Col> */}
                    </Row>

                    {/* <Row>
                        <Col xl="6" className='align-self-center'>
                            <Row className='mb-3'>
                                <Label className='mb-0'>Hizmet Başlangıcı:</Label>
                                <Input type="date" value={serviceStart} onChange={(e) => setServiceStart(e.target.value)}></Input>
                            </Row>
                        </Col>
                        <Col xl="6" className='align-self-center'>

                            <Row className='mb-3'>
                                <Label className='mb-0'>Hizmet Bitişi:</Label>
                                <Input type="date" value={serviceEnd} onChange={(e) => setServiceEnd(e.target.value)}></Input>
                            </Row>
                        </Col>
                    </Row> */}
                    <Row className='justify-content-center'>
                        <Col xl="6" className='text-center'>
                            <button type="button" className="btn btn-primary m-2" onClick={addNewBrand}>Yeni Marka Ekle</button>
                        </Col>
                    </Row>

                </div>
            </div>}
        </>
    )
}

export default Brands