import React, { useEffect, useState } from 'react'
import brandsLogo from '../images/butik-icerik-logo-beyaz.png'
import { useSiteContext } from '../context/SiteContext';
import { Button, Col, Row, Label, Input } from 'reactstrap';
import ReactQuill from 'react-quill';
import 'quill/dist/quill.snow.css';
import db from '../firebase';

const Posts = ({ content }) => {

    const { profile, currentMonth } = useSiteContext();

    const [contentInput, setContentInput] = useState();
    const [editorDetails, setEditorDetails] = useState();
    const [authorDetails, setAuthorDetails] = useState();
    const [brandDetails, setBrandDetails] = useState();
    const [wordCount, setWordCount] = useState();
    const [letterCount, setLetterCount] = useState();
    const [titleDetails, setTitleDetails] = useState();
    const [titleWordCount, setTitleWordCount] = useState(0);

    useEffect(() => {
        db.collection("profile").where("uid", "==", content.editorId).onSnapshot(snapshot => (
            setEditorDetails(snapshot.docs.map(doc => (
                {
                    totalPrice: doc.data().totalPrice
                }
            )))
        ))
        db.collection("profile").where("uid", "==", content.authorId).onSnapshot(snapshot => (
            setAuthorDetails(snapshot.docs.map(doc => (
                {
                    totalPrice: doc.data().totalPrice
                }
            )))
        ))
        db.collection("brands").where("uid", "==", content.brandId).onSnapshot(snapshot => (
            setBrandDetails(snapshot.docs.map(doc => (
                {
                    totalPrice: doc.data().totalPrice
                }
            )))
        ))
    }, [])

    const addNewTitle = () => {
        const keepContent = contentInput;
        const keepPureContent = keepContent.replace('<p>', '').replace('</p>', '').replace('<br>', '').replace('<h1>', '').replace('</h1>', '').replace('<h2>', '').replace('</h2>', '').replace('<h3>', '').replace('</h3>', '').replace('<strong>', '').replace('</strong>', '').replace('<em>', '').replace('</em>', '').replace('<u>', '').replace('</u>', '');
        setContentInput();
        db.collection("titles").doc(content.id).update({
            post: keepPureContent && keepPureContent ? keepPureContent : "",
            titleWordCount: titleWordCount && titleWordCount ? titleWordCount : "",
            status: "Editör"
        });
    }


    const revisedTitleHandler = () => {
        const keepContent = contentInput;
        const keepPureContent = keepContent.replace('<p>', '').replace('</p>', '').replace('<br>', '').replace('<h1>', '').replace('</h1>', '').replace('<h2>', '').replace('</h2>', '').replace('<h3>', '').replace('</h3>', '').replace('<strong>', '').replace('</strong>', '').replace('<em>', '').replace('</em>', '').replace('<u>', '').replace('</u>', '');
        setContentInput();
        if (profile && profile[0].userLevel === "2") {
            db.collection("titles").doc(content.id).update({
                revised: keepPureContent && keepPureContent ? keepPureContent : "",
                status: "Editör"
            });

        }
        if (profile && profile[0].userLevel === "1") {
            db.collection("titles").doc(content.id).update({
                revised: keepPureContent && keepPureContent ? keepPureContent : "",
                status: "Yazar"
            });

        }
        if (profile && profile[0].userLevel === "3") {
            db.collection("titles").doc(content.id).update({
                revised: keepPureContent && keepPureContent ? keepPureContent : "",
                status: "Onay"
            });

        }
    }


    const bringTheTitleHandler = () => {
        setContentInput(content.post)
    }


    const acceptEditorHandler = () => {
        db.collection("titles").doc(content.id).update({
            status: "Onay"
        });
    }
    const acceptAdminHandler = () => {
        db.collection("titles").doc(content.id).update({
            status: "Marka"
        });
        // db.collection("price").doc("totalPrice").collection(currentMonth + new Date().getFullYear()).doc(content.editorId).set({
        //     totalPrice: editorDetails && parseInt(editorDetails[0].totalPrice) + parseInt(content.editorPrice)
        // });
        // db.collection("price").doc("totalPrice").collection(currentMonth + new Date().getFullYear()).doc(content.authorId).update({
        //     totalPrice: authorDetails && parseInt(authorDetails[0].totalPrice) + parseInt(content.authorPrice)
        // });
        // db.collection("profile").doc("profile_" + content.editorId).update({
        //     totalPrice: editorDetails && parseInt(editorDetails[0].totalPrice) + parseInt(content.editorPrice)
        // });

        // db.collection("profile").doc("profile_" + content.authorId).update({
        //     totalPrice: authorDetails && parseInt(authorDetails[0].totalPrice) + parseInt(content.authorPrice)
        // });
        // db.collection("brand").doc(content.brandId).update({
        //     totalPrice: brandDetails && parseInt(brandDetails[0].totalPrice) + parseInt(content.totalPrice)
        // });

    }


    const acceptBrandHandler = () => {
        db.collection("titles").doc(content.id).update({
            status: "Yayın"
        });
    }

    useEffect(() => {
        if (contentInput && contentInput.length > 0) {
            const letterHandle = contentInput && contentInput?.replace('<p>', '').replace('</p>', '').replace('<br>', '').replace('<h1>', '').replace('</h1>', '').replace('<h2>', '').replace('</h2>', '').replace('<h3>', '').replace('</h3>', '').replace('<strong>', '').replace('</strong>', '').replace('<em>', '').replace('</em>', '').replace('<u>', '').replace('</u>', '');
            const letterHandle_ = letterHandle.trim();
            const letterHandle_a = letterHandle.replace(/\s/g, "");
            setTitleWordCount(letterHandle_.split(" ").length)
            setLetterCount(letterHandle_a.length);
        }
    }, [addNewTitle])

    useEffect(() => {

        if (content.id) {
            db.collection("titles").where("id", "==", content.id).onSnapshot(snapshot => (
                setTitleDetails(snapshot.docs.map(doc => (
                    {
                        post: doc.data().post,
                        wordCount: doc.data().wordCount,
                        revised: doc.data().revised,
                        title: doc.data().title

                    }
                )
                ))
            ));
        }
    }, [])


    return (
        <>
            {titleDetails && titleDetails[0]?.revised ? <Row className='mb-3'>
                <Col xl="12">
                    <h4> Revize Notları </h4>
                    <div> {titleDetails && titleDetails[0]?.revised} </div>
                </Col>
            </Row> : <></>}
            <Row className='my-3'>
                <Col>
                    <Row className='my-3'>
                        <Col className='align-self-center text-center'>
                            <h3 className='mb-0'> {content.title} </h3>
                            <hr />
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col xl="12" className='align-self-center text-start'>
                            <Label className='mb-0' style={titleWordCount < (parseInt(content.wordCount) - 50) ? { backgroundColor: "red", color: "#fff" } : titleWordCount == (parseInt(content.wordCount) - 50) ? { backgroundColor: "orange", color: "#fff" } : titleWordCount > (parseInt(content.wordCount) - 50) ? { backgroundColor: "green", color: "#fff" } : {}}> Kelime Sayısı : {titleWordCount} / {content.wordCount} </Label>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col xl="12">
                        </Col>
                        <ReactQuill value={contentInput} onChange={setContentInput} />
                    </Row>

                    <Row className='justify-content-center mt-5'>
                        <Col xl="12" className='text-center'>
                            {profile && profile[0].userLevel != "3" ? <><button type="button" className="btn btn-primary m-2" disabled={titleWordCount < (parseInt(content.wordCount) - 50)} onClick={addNewTitle}>Gönder</button>
                                <button type="button" className="btn btn-primary m-2" onClick={bringTheTitleHandler}>Düzenle</button></> : <></>}
                            {profile && profile[0].userLevel != "0" ? <>  <button type="button" className="btn btn-primary m-2" onClick={revisedTitleHandler}>Revize Gönder</button> </> : <></>}
                            {profile && profile[0].userLevel === "1" ? <> <button type="button" className="btn btn-primary m-2" onClick={acceptEditorHandler}>Onayla</button></> : <></>}
                            {profile && profile[0].userLevel === "2" ? <> <button type="button" className="btn btn-primary m-2" onClick={acceptAdminHandler}>Onayla</button></> : <></>}
                            {profile && profile[0].userLevel === "3" ? <> <button type="button" className="btn btn-primary m-2" onClick={acceptBrandHandler}>Onayla</button></> : <></>}
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row className='my-3'>
                        <Col className='align-self-center text-center'>
                            <h3 className='mb-0'> {content.title} </h3>
                            <hr />
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col xl="12" className='align-self-center text-start'>
                            <Label className='mb-0'> Kelime Sayısı : {content.titleWordCount} / {content.wordCount} </Label>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col xl="12">
                            <div> {titleDetails && titleDetails[0]?.post} </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Col className='my-3'>
                <Row className='my-3'>
                    <Col className='align-self-center text-center'>
                        <h5 className='mb-0'> {content.title} Brif: </h5>
                        <p> {content.brief} </p>
                        <hr />
                        <h5 className='mb-0'> {content.title} Anahtar Kelimeler: </h5>
                        <p> {content.keyWords} </p>
                        <hr />
                    </Col>
                </Row>
            </Col>

        </>

    )
}

export default Posts