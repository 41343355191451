import React, { useEffect, useState } from 'react'
import { useSiteContext } from '../context/SiteContext';
import { Button, Col, Row, Label, Input } from 'reactstrap';
import db, { storage } from '../firebase';
import { deleteObject, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import toast from 'react-hot-toast';
import Loader from './Loader';
import { MdArrowBack, MdCloudUpload, MdCreate, MdDelete, MdOutlineUpdate, MdOutlineUpgrade } from 'react-icons/md'
import { nanoid } from 'nanoid'
import ContentCount from './updatePages/ContentCount';
import ContentPrice from './updatePages/ContentPrice';
import BrandSummary from './updatePages/BrandSummary';
import KeyWords from './updatePages/KeyWords';
import ServiceDeadLine from './updatePages/ServiceDeadLine';
import Posts from './Posts';
import { Link } from 'react-router-dom';




const BrandsDetailAdmin = () => {
    const { authors, editors, currentMonth, dogruAy, months } = useSiteContext();

    const [imgURL_, setImgURL_] = useState();
    const [tph, setTph] = useState(false);
    const [tph_, setTph_] = useState(false);
    const [brandDetails, setBrandDetails] = useState();
    const [titleDetails, setTitleDetails] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [imageAsset, setImageAsset] = useState(null);
    const [msg, setMsg] = useState(null);
    const [fields, setFields] = useState(false);
    const [alertStatus, setAlertStatus] = useState("danger");
    const [productImgURL, setProductImgURL] = useState();
    const [title, setTitle] = useState();
    const [selectAuthor, setSelectAuthor] = useState();
    const [selectEditor, setSelectEditor] = useState();
    const [titleStart, setTitleStart] = useState();
    const [titleEnd, setTitleEnd] = useState();
    const [selectStatus, setSelectStatus] = useState();
    const [logoModal, setLogoModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [updateModal_, setUpdateModal_] = useState();
    const [serviceType, setServiceType] = useState();
    const [wordCount, setWordCount] = useState();
    const [lang, setLang] = useState();
    const [editorPrice, setEditorPrice] = useState();
    const [authorPrice, setAuthorprice] = useState();
    const [brief, setBrief] = useState();
    const [keyWords, setKeyWords] = useState();
    const [totalPrice, setTotalPrice] = useState();
    const [selectedContent, setSelectedContent] = useState();
    const [statusChange, setStatusChange] = useState("Yazar");
    const [selectedMonth, setSelectedMonth] = useState(dogruAy);
    const [selectServices, setSelectServices] = useState();
    const [social, setSocial] = useState({
        brief: "", cloudLink: "", name: "", type: "", startDate: "", status: ""
    })
    const [animation, setAnimation] = useState({
        brief: "", cloudLink: "", name: "", type: "", startDate: "", status: "", deadLine: "",
    });
    const [web, setWeb] = useState({
        briefFileLink: "", workLink: "", type: "", startDate: "", status: "", deadLine: "", link: ""
    });
    const [SEO, setSEO] = useState({
        brief: "", cloudLink: "", name: "", type: "", startDate: "", status: "", link: ""
    });
    const [content, setContent] = useState({
        title: "", startDate: "", type: "", status: "", cloudLink: ""
    });

    const addTitlePopup = () => {
        setTph_(current => !current)
    }

    const toggleHandle_ = () => {
        setTph(current => !current);
    }

    const year = titleStart?.split("-")[0]
    const month = titleStart?.split("-")[1]
    const day = titleStart?.split("-")[2]
    const year_ = titleEnd?.split("-")[0]
    const month_ = titleEnd?.split("-")[1]
    const day_ = titleEnd?.split("-")[2]
    const titleStartDate_ = day + "." + month + "." + year;
    const titleEndDate_ = day_ + "." + month_ + "." + year_;



    useEffect(() => {

        if (localStorage.getItem("brandId")) {
            db.collection("brands/").where("brandId", "==", localStorage.getItem("brandId")).onSnapshot(snapshot => (
                setBrandDetails(snapshot.docs.map(doc => (
                    {
                        id: doc.id,
                        brandName: doc.data().brandName,
                        brandId: doc.data().brandId,
                        contentPcs: doc.data().contentPcs,
                        serviceStart: doc.data().serviceStart,
                        serviceEnd: doc.data().serviceEnd,
                        keyWords: doc.data().keyWords,
                        imgURL: doc.data().imgURL, brandPrice: doc.data().brandPrice,
                        editorPrice: doc.data().editorPrice,
                        brandSummary: doc.data().brandSummary
                    }
                )
                ))
            ))
        }
    }, [])

    const addNewTitle = () => {
        const titleId = nanoid();
        if (title === "" || title === undefined || title === null) {
            toast.error("Başlık giriniz.");
            return;
        } else if (selectAuthor === "") {
            toast.error("Yazar seçiniz.");
            return;
        } else if (selectEditor === "") {
            toast.error("Editör seçiniz.");
            return;
        } else if (titleStart === null || titleStart === undefined) {
            toast.error("Başlangıç tarihini seçiniz.");
            return;
        } else if (titleEnd === null || titleEnd === undefined) {
            toast.error("Bitiş tarihini seçiniz.");
            return;
        } else if (titleStart > titleEnd) {
            toast.error("Başlagıç ve bitiş tarihini kontrol ediniz.");
            return;
        } else if (selectStatus === "") {
            toast.error("Başlık durumunu seçiniz.");
            return;
        } else {
            setTph_(current => !current);
            db.collection("titles").doc(titleId).set({
                author: JSON.parse(selectAuthor).name,
                authorId: JSON.parse(selectAuthor).uid,
                authorPrice: authorPrice,
                brand: brandDetails && brandDetails[0]?.brandName,
                brandId: localStorage.getItem("brandId"),
                brief: brief,
                editor: JSON.parse(selectEditor).name,
                editorId: JSON.parse(selectEditor).uid,
                editorPrice: editorPrice,
                endDate: titleEndDate_,
                id: titleId,
                keyWords: keyWords,
                language: lang,
                serviceType: serviceType,
                sortEndDate: titleEnd,
                sortStartDate: titleStart,
                startDate: titleStartDate_,
                status: selectStatus,
                title: title,
                totalPrice: totalPrice,
                wordCount: wordCount,

            });
            setContent({
                title: "", startDate: "", type: "", status: "", cloudLink: ""
            });
        }
    }
    const addNewSEO = () => {
        const id = nanoid();
        if (SEO.siteLink === "" || SEO.siteLink === undefined || SEO.siteLink === null) {
            toast.error("Site Linkini Giriniz.");
            return;
        } else if (SEO.startDate === "" || SEO.startDate === undefined || SEO.startDate === null) {
            toast.error("Başlangıç Tarihi Giriniz.");
            return;
        } else {
            setTph_(current => !current);
            db.collection("seo").doc(id).set({
                id: id,
                brand: brandDetails && brandDetails[0]?.brandName,
                brandId: localStorage.getItem("brandId"),
                siteLink: SEO.siteLink,
                startDate: SEO.startDate,
                cloudLink: SEO.cloudLink,
                status: "Hazırlanıyor",

            });
            setSEO({
                brief: "", cloudLink: "", name: "", type: "", startDate: "", status: ""
            });
        }
    }
    const addNewWeb = () => {
        const id = nanoid();
        if (web.startDate === "" || web.startDate === undefined || web.startDate === null) {
            toast.error("Başlangıç Tarihi giriniz.");
            return;
        } else if (web.deadLine === "" || web.deadLine === undefined || web.deadLine === null) {
            toast.error("Deadline giriniz.");
            return;
        }
        else {
            setTph_(current => !current);
            db.collection("web").doc(id).set({
                brand: brandDetails && brandDetails[0]?.brandName,
                brandId: localStorage.getItem("brandId"),
                id: id,
                workLink: web.workLink,
                briefFileLink: web.briefFileLink,
                type: web.type,
                status: "Hazırlanıyor",
                startDate: web.startDate,
                deadLine: web.deadLine,
                link: web.link
            });
            setWeb({
                briefFileLink: "", workLink: "", type: "", startDate: "", status: "", deadLine: "", link: ""
            });
        }
    }
    const addNewAnimation = () => {
        const id = nanoid();
        if (animation.name === "" || animation.name === undefined || animation.name === null) {
            toast.error("Başlık giriniz.");
            return;
        } else if (animation.type === "" || animation.type === undefined || animation.type === null) {
            toast.error("Kategori seçiniz.");
            return;
        } else if (animation.cloudLink === "" || animation.cloudLink === undefined || animation.cloudLink === null) {
            toast.error("Link giriniz.");
            return;
        } else {
            setTph_(current => !current);
            db.collection("animation").doc(id).set({
                brand: brandDetails && brandDetails[0]?.brandName,
                brandId: localStorage.getItem("brandId"),
                brief: animation.brief,
                id: id,
                startDate: animation.startDate,
                status: "Hazırlanıyor",
                cloudLink: animation.cloudLink,
                postName: animation.name,
                postType: animation.type,
                startDate: animation.startDate,
                deadLine: animation.deadLine,
            });
            setAnimation({
                brief: "", cloudLink: "", name: "", type: "", startDate: "", status: "", deadLine: ""
            });
        }
    }
    const addNewSocial = () => {
        const id = nanoid();
        if (social.name === "" || social.name === undefined || social.name === null) {
            toast.error("Başlık giriniz.");
            return;
        } else if (social.type === "" || social.type === undefined || social.type === null) {
            toast.error("Kategori seçiniz.");
            return;
        } else if (social.cloudLink === "" || social.cloudLink === undefined || social.cloudLink === null) {
            toast.error("Link giriniz.");
            return;
        } else {
            setTph_(current => !current);
            db.collection("social").doc(id).set({
                brand: brandDetails && brandDetails[0]?.brandName,
                brandId: localStorage.getItem("brandId"),
                brief: social.brief,
                id: id,
                cloudLink: social.cloudLink,
                postName: social.name,
                postType: social.type,
                startDate: social.startDate,
                status: "Hazırlanıyor"
            });
            setSocial({
                brief: "", cloudLink: "", name: "", type: "", startDate: "", status: ""
            });
        }
    }

    useEffect(() => {
        if (localStorage.getItem("brandId")) {
            db.collection("titles").where("brandId", "==", localStorage.getItem("brandId")).onSnapshot(snapshot => (
                setTitleDetails(snapshot.docs.map(doc => (
                    {
                        id: doc.id,
                        title: doc.data().title,
                        serviceType: doc.data().serviceType,
                        wordCount: doc.data().wordCount,
                        titleWordCount: doc.data().titleWordCount,
                        author: doc.data().author,
                        authorId: doc.data().authorId,
                        authorPrice: doc.data().authorPrice,
                        editor: doc.data().editor,
                        editorId: doc.data().editorId,
                        editorPrice: doc.data().editorPrice,
                        status: doc.data().status,
                        startDate: doc.data().startDate,
                        endDate: doc.data().endDate,
                        sortEndDate: doc.data().sortEndDate,
                        post: doc.data().post,
                        revised: doc.data().revised,
                        brief: doc.data().brief,
                        language: doc.data().language,
                        keyWords: doc.data().keyWords,
                        brand: doc.data().brand,
                        brandId: doc.data().brandId,
                        totalPrice: doc.data().totalPrice

                    }
                )
                ))
            ))
        }

    }, [])

    const brandUpdateModal = (i) => {
        setUpdateModal(current => !current);
        console.log("i= " + i);
        if (i === 1) {
            setUpdateModal_(1);
        } else if (i === 2) {
            setUpdateModal_(2)
        } else if (i === 3) {
            setUpdateModal_(3)
        } else if (i === 4) {
            setUpdateModal_(4)
        } else if (i === 5) {
            setUpdateModal_(5)
        } else if (i === 6) {
            setUpdateModal_(6)
        }
    }

    const uploadLogoModal = () => {
        setLogoModal(current => !current);
    }

    const uploadLogo = () => {
        db.collection("brands/").doc(localStorage.getItem("brandId")).update(
            {
                imgURL: imgURL_ ? imgURL_ : ""
            }
        )
        setLogoModal(current => !current)
    }

    const deleteLogo = () => {
        deleteObject(ref(storage, brandDetails && brandDetails[0].imgURL));
        db.collection("brands/").doc(localStorage.getItem("brandId")).update(
            {
                imgURL: ""
            }
        )

    }

    const uploadImage = (e) => {
        setIsLoading(true);
        const imageFile = e.target.files[0];
        const storageRef = ref(storage, `logos/${localStorage.getItem("brandId")}`);
        const uploadTask = uploadBytesResumable(storageRef, imageFile);

        uploadTask.on('state_changed', (snapshot) => {
            const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        }, (error) => {
            console.log(error);
            setFields(true);
            setMsg('Error while uploading : Try Again🙇‍♂️');
            setAlertStatus('danger');
            setTimeout(() => {
                setFields(false);
                setIsLoading(false);
            }, 4000)
        }, () => {
            getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                setImageAsset(downloadURL);
                setProductImgURL(downloadURL);
                console.log(downloadURL)
                setIsLoading(false);
                setFields(true);
                setImgURL_(downloadURL)
                toast.success('Image uploaded succesfully😎');
                setAlertStatus("success");
                setTimeout(() => {
                    setFields(false);
                }, 4000);

            })
        });
    }

    const deleteImage = () => {
        setIsLoading(true);
        const deleteRef = ref(storage, imageAsset);
        deleteObject(deleteRef).then(() => {
            setImageAsset(null);
            setIsLoading(false);
            setFields(true);
            setMsg('Image deleted succesfully🚮🗑');
            setTimeout(() => {
                setFields(false);
            }, 4000);
        })
    }

    const postHandle = (id, title, post, wordCount, editorId, editorPrice, authorId, authorPrice, totalPrice, brand, brandId, titleWordCount, status, brief, keyWords) => {
        setSelectedContent(
            { id: id, title: title, post: post, wordCount: wordCount, editorId: editorId, editorPrice: editorPrice, authorId: authorId, authorPrice: authorPrice, totalPrice: totalPrice, brand: brand, brandId: brandId, titleWordCount: titleWordCount, status: status, brief: brief, keyWords: keyWords }
        )
        setTph(current => !current)
    }



    return (
        <>
            <div className="container-fluid pt-4 px-4">
                <Row>
                    <Col className='text-end'>
                        <button type="button" className="btn btn-primary m-2" onClick={addTitlePopup}>Hizmet Ekle <b>+</b></button>
                    </Col>
                </Row>

                {tph_ && <>
                    <Row>
                        <Col className='mb-3 text-start'>
                            <select className="form-select" aria-label="Default select example" onChange={(e) => setSelectServices(e.target.value)}>
                                <option value={""} >Hizmet Seçiniz</option>
                                <option value={"web"} >Web Projesi</option>
                                <option value={"seo"} >SEO Hizmeti</option>
                                <option value={"content"} >İçerik Hizmeti</option>
                                <option value={"social"} >Sosyal Medya Hizmeti</option>
                                <option value={"animation"} >Animasyon Video Hizmeti</option>
                            </select>
                        </Col>
                    </Row>
                    {selectServices === "content" ? <>
                        <div className="bg-secondary text-center rounded p-4">
                            <Row className=''>
                                <Row >
                                    <Col className='align-self-center text-center'>
                                        <h4 className='mb-0'>İçerik Ekle</h4>
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3 text-start'>
                                        <Label className='mb-0'>Başlık:</Label>
                                        <Input type="text" value={title} onChange={(e) => setTitle(e.target.value)}></Input>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3' >
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Başlık Türü:</Label>
                                        </Col>
                                        <Col >
                                            <select className="form-select" aria-label="Default select example" onChange={(e) => setServiceType(e.target.value)}>
                                                <option value={""} >Hizmet Türü</option>
                                                <option value={"Blog"}>Blog</option>
                                                <option value={"Kategori"}>Kategori</option>
                                                <option value={"Ürün"}>Ürün</option>
                                                <option value={"Tanıtım Filmi"}>Tanıtım Filmi</option>
                                                <option value={"Katalog"}>Katalog</option>
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col className='mb-3' xl="6">
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Kelime Sayısı:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="number" value={wordCount} onChange={(e) => setWordCount(e.target.value)}></Input>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3'>
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Yazar:</Label>
                                        </Col>
                                        <Col>
                                            <select className="form-select" aria-label="Default select example" onChange={(e) => setSelectAuthor(e.target.value)}>
                                                <option value={""} >Yazar Seçiniz</option>
                                                {authors?.map((authors, index) => (
                                                    <option value={JSON.stringify(authors)}>{authors.name}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col className='mb-3'>
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Editör:</Label>
                                        </Col>
                                        <Col >
                                            <select className="form-select" aria-label="Default select example" onChange={(e) => setSelectEditor(e.target.value)}>
                                                <option value={""} >Editör Seçiniz</option>
                                                {editors?.map((editor, index) => (
                                                    <option key={index} value={JSON.stringify(editor)}>{editor.name}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3'>
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Başlangıcı:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="date" value={titleStart} onChange={(e) => setTitleStart(e.target.value)}></Input>
                                        </Col>
                                    </Col>
                                    <Col className='mb-3'>
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Bitişi:</Label>
                                        </Col>
                                        <Col>
                                            <Input type="date" value={titleEnd} onChange={(e) => setTitleEnd(e.target.value)}></Input>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3'>
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Dil:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="text" value={lang} onChange={(e) => setLang(e.target.value)}></Input>
                                        </Col>
                                    </Col>
                                    <Col className='mb-3'>
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Başlık Durumu:</Label>
                                        </Col>
                                        <Col >
                                            <select className="form-select" aria-label="Default select example" onChange={(e) => setSelectStatus(e.target.value)}>
                                                <option value={""} >Durum Seçiniz</option>
                                                <option value={"Yazar"}>Yazar</option>
                                                <option value={"Editör"}>Editör</option>
                                                <option value={"Marka"}>Marka</option>
                                                <option value={"Yayın"}>Yayın</option>
                                            </select>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3'>
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Yazar Fiyatı:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="number" value={authorPrice} onChange={(e) => setAuthorprice(e.target.value)}></Input>
                                        </Col>
                                    </Col>
                                    <Col className='mb-3'>
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Editör Fiyatı:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="number" value={editorPrice} onChange={(e) => setEditorPrice(e.target.value)}></Input>

                                        </Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3'>
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Fiyat:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="number" value={totalPrice} onChange={(e) => setTotalPrice(e.target.value)}></Input>
                                        </Col>
                                    </Col>
                                    <Col className='mb-3'>
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Anahtar Kelimeler:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="text" value={keyWords} onChange={(e) => setKeyWords(e.target.value)}></Input>

                                        </Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3'>
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Brief:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="textarea" value={brief} onChange={(e) => setBrief(e.target.value)}></Input>

                                        </Col>
                                    </Col>
                                </Row>
                                <Row className='justify-content-center'>
                                    <Col xl="6" className='text-center'>
                                        <button type="button" className="btn btn-primary m-2" onClick={addNewTitle}>Yeni Başlık Ekle</button>
                                    </Col>
                                </Row>
                            </Row>
                        </div>  </> : null}
                    {/* TAMAMLANDI  */}
                    {selectServices === "seo" ? <>
                        <div className="bg-secondary text-center rounded p-4">
                            <Row className=''>
                                <Row >
                                    <Col className='align-self-center text-center'>
                                        <h4 className='mb-0'>SEO Hizmeti Ekle</h4>
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3' xl="6">
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Site Linki:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="text" value={SEO.link} onChange={(e) => setSEO({ ...SEO, link: (e.target.value) })}></Input>
                                        </Col>
                                    </Col>
                                    <Col className='mb-3' xl="6">
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Çalışma Linki - İçerikler:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="text" value={SEO.cloudLink} onChange={(e) => setSEO({ ...SEO, workLink: (e.target.value) })}></Input>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3'>
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Başlangıcı:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="text" value={SEO.startDate} onChange={(e) => setSEO({ ...SEO, startDate: (e.target.value) })}></Input>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row className='justify-content-center'>
                                    <Col xl="6" className='text-center'>
                                        <button type="button" className="btn btn-primary m-2" onClick={addNewSEO}>Ekle</button>
                                    </Col>
                                </Row>
                            </Row>
                        </div></> : null}
                    {/* TAMAMLANDI */}
                    {selectServices === "web" ? <>
                        <div className="bg-secondary text-center rounded p-4">
                            <Row className=''>
                                <Row >
                                    <Col className='align-self-center text-center'>
                                        <h4 className='mb-0'>Web Projesi Ekle</h4>
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3' >
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Proje Türü:</Label>
                                        </Col>
                                        <Col >
                                            <select className="form-select" aria-label="Default select example" onChange={(e) => setWeb({ ...web, type: (e.target.value) })}>
                                                <option value={""} >Proje Türü</option>
                                                <option value={"Mobil Uygulama"}>Mobil Uygulama</option>
                                                <option value={"Web Uygulaması"}>Web Uygulaması</option>
                                                <option value={"Kurumsal Web Sitesi"}>Kurumsal Web Sitesi</option>
                                                <option value={"E-Ticaret Web Sitesi"}>E-Ticaret Web Sitesi</option>
                                                <option value={"Diğer"}>Diğer</option>
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col className='mb-3' xl="6">
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Çalışma Linki:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="text" value={web.workLink} onChange={(e) => setWeb({ ...web, workLink: (e.target.value) })}></Input>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3' xl="6">
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Brief Linki:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="text" value={web.briefFileLink} onChange={(e) => setWeb({ ...web, briefFileLink: (e.target.value) })}></Input>
                                        </Col>
                                    </Col>
                                    <Col className='mb-3' xl="6">
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Linki:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="text" value={web.link} onChange={(e) => setWeb({ ...web, link: (e.target.value) })}></Input>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3'>
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Başlangıcı:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="text" value={web.startDate} onChange={(e) => setWeb({ ...web, startDate: (e.target.value) })}></Input>
                                        </Col>
                                    </Col>
                                    <Col className='mb-3'>
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Bitişi:</Label>
                                        </Col>
                                        <Col>
                                            <Input value={web.deadLine} onChange={(e) => setWeb({ ...web, deadLine: (e.target.value) })}></Input>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row className='justify-content-center'>
                                    <Col xl="6" className='text-center'>
                                        <button type="button" className="btn btn-primary m-2" onClick={addNewWeb}>Ekle</button>
                                    </Col>
                                </Row>
                            </Row>
                        </div></> : null}
                    {/* TAMAMLANDI */}
                    {selectServices === "animation" ? <>
                        <div className="bg-secondary text-center rounded p-4">
                            <Row className=''>
                                <Row >
                                    <Col className='align-self-center text-center'>
                                        <h4 className='mb-0'>Animasyon Video Hizmeti Ekle</h4>
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3 text-start'>
                                        <Label className='mb-0'>Çalışma Adı:</Label>
                                        <Input type="text" value={animation.name} onChange={(e) => setAnimation({ ...animation, name: (e.target.value) })}></Input>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3' >
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Çalışma Türü Türü:</Label>
                                        </Col>
                                        <Col >
                                            <select className="form-select" aria-label="Default select example" onChange={(e) => setAnimation({ ...animation, type: (e.target.value) })}>
                                                <option value={""} >Çalışma Türü</option>
                                                <option value={"2D Animasyon"}>2D Animasyon</option>
                                                <option value={"3D Animasyon"}>3D Animasyon</option>
                                                <option value={"Gerçek Çekim"}>Gerçek Çekim</option>
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col className='mb-3' xl="6">
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Çalışma Linki:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="text" value={animation.cloudLink} onChange={(e) => setAnimation({ ...animation, cloudLink: (e.target.value) })}></Input>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3'>
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Başlangıç Tarihi:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="text" placeholder='11.11.2011' value={animation.startDate} onChange={(e) => setAnimation({ ...animation, startDate: (e.target.value) })}></Input>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3'>
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Brief:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="textarea" value={animation.brief} onChange={(e) => setAnimation({ ...animation, brief: (e.target.value) })}></Input>

                                        </Col>
                                    </Col>
                                </Row>
                                <Row className='justify-content-center'>
                                    <Col xl="6" className='text-center'>
                                        <button type="button" className="btn btn-primary m-2" onClick={addNewAnimation}>Ekle</button>
                                    </Col>
                                </Row>
                            </Row>
                        </div></> : null}
                    {/* TAMAMLANDI */}
                    {selectServices === "social" ? <>
                        <div className="bg-secondary text-center rounded p-4">
                            <Row className=''>
                                <Row >
                                    <Col className='align-self-center text-center'>
                                        <h4 className='mb-0'>Sosyal Medya Hizmeti Ekle</h4>
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3 text-start'>
                                        <Label className='mb-0'>Çalışma Adı:</Label>
                                        <Input type="text" value={social.name} onChange={(e) => setSocial({ ...social, name: (e.target.value) })}></Input>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3' >
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Çalışma Türü Türü:</Label>
                                        </Col>
                                        <Col >
                                            <select className="form-select" aria-label="Default select example" onChange={(e) => setSocial({ ...social, type: (e.target.value) })}>
                                                <option value={""} >Çalışma Türü</option>
                                                <option value={"Reels"}>Reels</option>
                                                <option value={"Post"}>Post</option>
                                                <option value={"Story"}>Story</option>
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col className='mb-3' xl="6">
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Çalışma Linki:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="text" value={social.cloudLink} onChange={(e) => setSocial({ ...social, cloudLink: (e.target.value) })}></Input>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3'>
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Başlangıç Tarihi:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="text" placeholder='11.11.2011' value={social.startDate} onChange={(e) => setSocial({ ...social, startDate: (e.target.value) })}></Input>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3'>
                                        <Col className='align-self-center text-start'>
                                            <Label className='mb-0'>Brief:</Label>
                                        </Col>
                                        <Col >
                                            <Input type="textarea" value={social.brief} onChange={(e) => setSocial({ ...social, brief: (e.target.value) })}></Input>

                                        </Col>
                                    </Col>
                                </Row>
                                <Row className='justify-content-center'>
                                    <Col xl="6" className='text-center'>
                                        <button type="button" className="btn btn-primary m-2" onClick={addNewSocial}>Ekle</button>
                                    </Col>
                                </Row>
                            </Row>
                        </div></> : null}

                </>}
                {!tph_ && <>
                    <div className="row g-4">
                        <div className="col-sm-6 col-xl-3">
                            <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">

                                <div className="ms-3">
                                    {logoModal && <div className="popup-box">
                                        <div className="box">
                                            <span className="close-icon" onClick={uploadLogoModal}>x</span>
                                            <Row className='mb-3'>
                                                <Col className='align-self-center text-center'>
                                                    <h3 className='mb-0'>Logo Yükle</h3>
                                                    <hr />
                                                </Col>
                                            </Row>
                                            <Row className='mb-3'>
                                                {isLoading ? <Loader /> : (<>
                                                    {!imageAsset ? (<>
                                                        <label className='w-full h-full flex flex-col items-center justify-center cursor-pointer'>
                                                            <div className='w-full h-full flex flex-col items-center justify-center cursor-pointer gap-2'>
                                                                <MdCloudUpload className='text-gray-500 text-3xl hover:text-gray-700' />
                                                                <p className='text-gray-500 text-xl'> Görsel yükle</p>
                                                            </div>
                                                            <input type="file" name="uploadimage" accept='image/*' onChange={uploadImage} className='w-0 h-0 ' />
                                                        </label>
                                                    </>) :
                                                        (<> <div className='relative h-20 '>
                                                            <img src={imageAsset} alt="uploaded image" style={{ width: 200 }} />
                                                            <button type='button' className='absolute bottom-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-500 transition-all' onClick={deleteImage}>
                                                                <MdDelete className='text-white' />
                                                            </button>
                                                        </div>
                                                        </>)}
                                                </>)}
                                                <Button onClick={uploadLogo} > Logoyu Kaydet </Button>
                                            </Row>
                                        </div>
                                    </div>}
                                    {brandDetails && brandDetails[0].imgURL === "" ? <div>
                                        <button type="button" className="btn btn-primary m-2" onClick={uploadLogoModal}>Logo Yükle</button></div> : <div><Button onClick={deleteLogo}> <img src={brandDetails && brandDetails[0].imgURL} width="140" /></Button></div>}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3">
                            <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                                <div className="ms-3">
                                    <p className="mb-2" >Aylık ve Toplam İçerik Sayısı <MdCreate onClick={() => brandUpdateModal(2)} /> </p>
                                    <h6 className="mb-0"> {brandDetails && brandDetails[0]?.contentPcs} - {titleDetails && titleDetails.length} </h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3">
                            <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                                <div className="ms-3">
                                    <p className="mb-2">Hizmet Başlangıç/Bitiş <MdCreate onClick={() => brandUpdateModal(6)} /> </p>
                                    <h6 className="mb-0"> {brandDetails && brandDetails[0]?.serviceStart} / {brandDetails && brandDetails[0]?.serviceEnd}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3">
                            <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                                <div className="ms-3">
                                    <p className="mb-2">İçerik Adet Fiyatı<MdCreate onClick={() => brandUpdateModal(3)} /> </p>
                                    <h6 className="mb-0">Marka: {brandDetails && brandDetails[0]?.brandPrice}₺ / Editör: {brandDetails && brandDetails[0]?.editorPrice}₺ </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-4">
                        <div className="col-sm-6 col-xl-4">
                            <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">

                                <div className="ms-3">
                                    <Link to={"/social-detail-admin"} ><button type="button" className="btn btn-primary m-2">Sosyal Medya Hizmeti</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-4">
                            <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">

                                <div className="ms-3">
                                    <Link to={"/seo-detail-admin"} ><button type="button" className="btn btn-primary m-2">SEO Hizmeti</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-4">
                            <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                                <div className="ms-3">
                                    <Link to={"/web-detail-admin"} ><button type="button" className="btn btn-primary m-2">Web & Mobil Projeleri </button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-4">
                        <div className="col-sm-6 col-xl-6">
                            <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">

                                <div className="ms-3">
                                    <Link to={"/animation-detail-admin"} ><button type="button" className="btn btn-primary m-2">Animasyon Video Hizmeti</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-6">
                            <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">

                                <div className="ms-3">
                                    <Link to={"/content-detail-admin"} ><button type="button" className="btn btn-primary m-2">İçerik Hizmeti</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-4 mb-5">
                        <div className="col-sm-6 col-xl-6">
                            <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                                <div className="ms-3">
                                    <p className="mb-2">Marka Özeti <MdCreate onClick={() => brandUpdateModal(4)} /> </p>
                                    <h6 className="mb-0"> {brandDetails && brandDetails[0]?.brandSummary} </h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-6">
                            <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                                <div className="ms-3">
                                    <p className="mb-2">Anahtar Kelimeler <MdCreate onClick={() => brandUpdateModal(5)} /> </p>
                                    <h6 className="mb-0"> {brandDetails && brandDetails[0]?.keyWords} </h6>
                                </div>
                            </div>
                        </div>

                    </div>

                    {updateModal && <div className="popup-box">
                        <div className="box">
                            <span className="close-icon" onClick={brandUpdateModal}>x</span>
                            {updateModal_ && updateModal_ === 2 ? <ContentCount brandId={brandDetails[0].id} brandContentPcs={brandDetails[0].contentPcs} setUpdateModal={setUpdateModal} /> : <></>}
                            {updateModal_ && updateModal_ === 3 ? <ContentPrice brandId={brandDetails[0].id} brandNewPrice={brandDetails[0].brandPrice} editorNewPrice={brandDetails[0].editorPrice} setUpdateModal={setUpdateModal} /> : <></>}
                            {updateModal_ && updateModal_ === 4 ? <BrandSummary brandId={brandDetails[0].id} brandNewSummary={brandDetails[0].brandSummary} setUpdateModal={setUpdateModal} /> : <></>}
                            {updateModal_ && updateModal_ === 5 ? <KeyWords brandId={brandDetails[0].id} brandKeyWords={brandDetails[0].keyWords} setUpdateModal={setUpdateModal} /> : <></>}
                            {updateModal_ && updateModal_ === 6 ? <ServiceDeadLine brandId={brandDetails[0].id} brandServiceStart={brandDetails[0].serviceStart} brandServiceEnd={brandDetails[0].serviceEnd} setUpdateModal={setUpdateModal} /> : <></>}
                        </div>
                    </div>}

                    {tph && <>
                        <Row>
                            <div className="bg-secondary text-left rounded p-4 my-5">

                                <MdArrowBack style={{ fontSize: 34, width: "5%", cursor: "pointer" }} onClick={toggleHandle_} />
                                <Row>
                                    <Posts content={selectedContent} />
                                </Row>
                            </div>
                        </Row>
                    </>}
                    {/* {!tph && <>
                        <div className="bg-secondary text-center rounded p-4">
                            <Row className='mb-3'>
                                <Col lg="2" className="d-flex align-items-center justify-content-between align-self-center">
                                    <select className="form-select" aria-label="Default select example" onChange={(e) => setSelectedMonth("0" + e.target.value)}>
                                        <option value={dogruAy.split("")[1]} > {currentMonth} </option>
                                        {months.map(month => <>
                                            <option value={month.number} > {month.name} </option>
                                        </>)}
                                    </select>
                                </Col>
                                <Col className="d-flex align-items-center justify-content-between align-self-center text-start"><h6 className='mt-2'> Ayı Başlıkları</h6></Col>
                            </Row>
                            <Row className='flex justify-content-start mb-2'>
                                <Col xl="auto"> <Button onClick={() => setStatusChange("Yazar")} > Yazar </Button> </Col>
                                <Col xl="auto"> <Button onClick={() => setStatusChange("Editör")}> Editör </Button> </Col>
                                <Col xl="auto"> <Button onClick={() => setStatusChange("Onay")}> Onay </Button> </Col>
                                <Col xl="auto"> <Button onClick={() => setStatusChange("Marka")}> Marka </Button> </Col>
                                <Col xl="auto"> <Button onClick={() => setStatusChange("Yayın")}> Yayın </Button> </Col>
                            </Row>
                            <div className="table-responsive">
                                <table className="table text-start align-middle table-bordered table-hover mb-0">
                                    <thead>
                                        <tr className="text-white">
                                            <th scope="col">Başlık Adı</th>
                                            <th scope="col">Yazar</th>
                                            <th scope="col">Editör</th>
                                            <th scope="col">İçerik Türü</th>
                                            <th scope="col">Kelime Sayısı</th>
                                            <th scope="col">Başlangıç Tarihi</th>
                                            <th scope="col">Bitiş Tarihi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {titleDetails?.filter(title => title.sortEndDate.split("-")[1] === selectedMonth).filter(title => title.status === statusChange).map((title) => (
                                            <tr key={title.id} >
                                                <td onClick={() => postHandle(title.id, title.title, title.post, title.wordCount, title.editorId, title.editorPrice, title.authorId, title.authorPrice, title.totalPrice, title.brand, title.brandId, title.titleWordCount, title.status, title.brief, title.keyWords)} className="pointer">{title.title}</td>
                                                <td>{title.author}</td>
                                                <td>{title.editor}</td>
                                                <td>{title.serviceType}</td>
                                                <td>{title.titleWordCount} / {title.wordCount}</td>
                                                <td>{title.startDate}</td>
                                                <td>{title.endDate}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>} */}

                </>}
            </div >

        </>
    )
}

export default BrandsDetailAdmin