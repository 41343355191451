import React from 'react'
import { useState } from 'react';
import { Col, Row, Label, Input } from 'reactstrap';
import db from '../../firebase';


const ServiceDeadLine = ({ brandId, brandServiceStart, brandServiceEnd, setUpdateModal }) => {

    const [updateServiceStart, setUpdateSwerviceStart] = useState();
    const [updateServiceEnd, setUpdateSwerviceEnd] = useState();

    const updateHandle = () => {
        db.collection("brands").doc(`${brandId}`).update({
            serviceStart: updateServiceStart ? updateServiceStart : brandServiceStart,
            serviceEnd: updateServiceEnd ? updateServiceEnd : brandServiceEnd
        })
        setUpdateModal(current => !current);
    }
    return (
        <>
            <Row className='mb-3'>
                <Col className='align-self-center text-center'>
                    <h3 className='mb-0'>Marka Bilgilerini Güncelle</h3>
                    <hr />
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col xl="4" className='align-self-center text-start'>
                    <Label className='mb-0'>Hizmet Başlangıç Tarihi:</Label>
                </Col>
                <Col xl="8">
                    <Input type="date" placeholder={brandServiceStart} value={updateServiceStart} onChange={(e) => setUpdateSwerviceStart(e.target.value)} ></Input>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col xl="4" className='align-self-center text-start'>
                    <Label className='mb-0'>Hizmet Bitiş Tarihi:</Label>
                </Col>
                <Col xl="8">
                    <Input type="date" placeholder={brandServiceEnd} value={updateServiceEnd} onChange={(e) => setUpdateSwerviceEnd(e.target.value)} ></Input>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col xl="6" className='text-center'>
                    <button type="button" className="btn btn-primary m-2" onClick={updateHandle}>Güncelle</button>
                </Col>
            </Row>
        </>
    )
}

export default ServiceDeadLine