import React, { useState, useEffect } from 'react'
import db from "../firebase";
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { MdArrowBack, MdCreate } from 'react-icons/md';
import { useSiteContext } from '../context/SiteContext';
import HomeContentPrice from '../components/updatePages/HomeContentPrice';
import Posts from './Posts';

const AdminHome = () => {

    const { profile, dogruAy, months, currentMonth } = useSiteContext();

    const [tph_, setTph_] = useState(false)
    const [brands, setBrands] = useState([]);
    const [allPrices, setAllPrices] = useState([]);
    const [titleDetails, setTitleDetails] = useState();
    const [updateModal_, setUpdateModal_] = useState(false);
    const [selectedPrice, setSelectedPrice] = useState();
    const [selectedName, setSelectedName] = useState();
    const [selectedUname, setSelectedUname] = useState();
    const [statusChange, setStatusChange] = useState("Onay");
    const [selectedMonth, setSelectedMonth] = useState(dogruAy);
    const [selectedContent, setSelectedContent] = useState();




    useEffect(() => {
        db.collection("brands").onSnapshot(snapshot => (
            setBrands(snapshot.docs.map(doc => (
                {
                    id: doc.id,
                    brandName: doc.data().brandName,
                    brandId: doc.data().brandId,
                    contentPcs: doc.data().contentPcs,
                    serviceStart: doc.data().serviceStart,
                    serviceEnd: doc.data().serviceEnd,
                    keyWords: doc.data().keyWords,
                    imgURL: doc.data().imgURL
                }
            )))
        ))
    }, []);

    useEffect(() => {
        db.collection("titles/").onSnapshot(snapshot => (
            setTitleDetails(snapshot.docs.map(doc => (
                {
                    id: doc.id,
                    title: doc.data().title,
                    serviceType: doc.data().serviceType,
                    wordCount: doc.data().wordCount,
                    titleWordCount: doc.data().titleWordCount,
                    author: doc.data().author,
                    authorId: doc.data().authorId,
                    authorPrice: doc.data().authorPrice,
                    editor: doc.data().editor,
                    editorId: doc.data().editorId,
                    editorPrice: doc.data().editorPrice,
                    status: doc.data().status,
                    startDate: doc.data().startDate,
                    endDate: doc.data().endDate,
                    sortEndDate: doc.data().sortEndDate,
                    post: doc.data().post,
                    revised: doc.data().revised,
                    brief: doc.data().brief,
                    language: doc.data().language,
                    keyWords: doc.data().keyWords,
                    brand: doc.data().brand,
                    brandId: doc.data().brandId,
                    totalPrice: doc.data().totalPrice
                }
            )
            ))
        ))

    }, []);

    const toggleHandle_ = () => {
        setTph_(current => !current)
    }


    useEffect(() => {
        db.collection("price").doc("price").collection("price").onSnapshot(snapshot => (
            setAllPrices(snapshot.docs.map(doc => (
                {
                    price: doc.data().price,
                    name: doc.data().name,
                    uname: doc.data().uname
                }
            )))
        ))
    }, []);


    const updateHandler = (a, b, c) => {
        setSelectedPrice(b);
        setSelectedName(a);
        setSelectedUname(c);
        setUpdateModal_(current => !current);
    }

    const postHandle = (id, title, post, wordCount, editorId, editorPrice, authorId, authorPrice, totalPrice, brand, brandId, titleWordCount, status, brief, keyWords) => {
        setSelectedContent(
            { id: id, title: title, post: post, wordCount: wordCount, editorId: editorId, editorPrice: editorPrice, authorId: authorId, authorPrice: authorPrice, totalPrice: totalPrice, brand: brand, brandId: brandId, titleWordCount: titleWordCount, status: status, brief: brief, keyWords: keyWords }
        )
        setTph_(current => !current)
    }




    return (
        <div className="container-fluid pt-4 px-4" >
            {profile && profile[0].userLevel == "2" ?
                <div className="row g-4 mb-5">
                    {allPrices.map((price, index) => (
                        <div key={index} className="col-sm-6 col-xl-3">
                            <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                                <div className="ms-3">
                                    <p className="mb-2">{price.name}</p>
                                    <h6 className="mb-0">
                                        {price.price}₺
                                        <MdCreate className='cursor-pointer' onClick={() => updateHandler(price.name, price.price, price.uname)} />
                                    </h6>
                                </div>
                            </div>
                        </div>

                    ))}

                    {updateModal_ &&
                        <div className="popup-box">
                            <div className="box">
                                <span className="close-icon" onClick={() => setUpdateModal_(current => !current)}>x</span>
                                <HomeContentPrice price={selectedPrice} contentName={selectedName} setUpdateModal_={setUpdateModal_} uname={selectedUname} />
                            </div>
                        </div>

                    }
                </div>
                : <></>}

            {tph_ && <>
                <div className="bg-secondary text-center rounded p-4 my-5">
                    <Row>

                        <MdArrowBack style={{ fontSize: 34, width: "5%", cursor: "pointer" }} onClick={toggleHandle_} />
                        <Row>
                            <Posts content={selectedContent} />
                        </Row>

                    </Row>
                </div></>}

            {!tph_ && <div className="bg-secondary text-center rounded p-4 my-5">

                <Row className='mb-3'>
                    <Col lg="2" className="d-flex align-items-center justify-content-between align-self-center">
                        <select className="form-select" aria-label="Default select example" onChange={(e) => setSelectedMonth("0" + e.target.value)}>
                            <option value={dogruAy.split("")[1]} > {currentMonth} </option>
                            {months.map(month => <>
                                <option value={month.number} > {month.name} </option>
                            </>)}
                        </select>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-between align-self-center text-start"><h6 className='mt-2'> Ayı Başlıkları</h6></Col>
                </Row>
                <Row className='flex justify-content-start mb-2'>
                    <Col xl="auto"> <Button onClick={() => setStatusChange("Yazar")} > Yazar </Button> </Col>
                    <Col xl="auto"> <Button onClick={() => setStatusChange("Editör")}> Editör </Button> </Col>
                    <Col xl="auto"> <Button onClick={() => setStatusChange("Onay")}> Onay </Button> </Col>
                    <Col xl="auto"> <Button onClick={() => setStatusChange("Marka")}> Marka </Button> </Col>
                    <Col xl="auto"> <Button onClick={() => setStatusChange("Yayın")}> Yayın </Button> </Col>
                </Row>

                <div className="table-responsive">
                    <table className="table text-start align-middle table-bordered table-hover mb-0">
                        <thead>
                            <tr className="text-white">
                                <th scope="col">Başlık Adı</th>
                                <th scope="col">Marka</th>
                                <th scope="col">Yazar</th>
                                <th scope="col">Editör</th>
                                <th scope="col">İçerik Türü</th>
                                <th scope="col">Kelime Sayısı</th>
                                <th scope="col">Başlangıç Tarihi</th>
                                <th scope="col">Bitiş Tarihi</th>
                                {/* <th scope="col">Durum</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {titleDetails?.filter(title => title.sortEndDate.split("-")[1] === selectedMonth).filter(title => title.status === statusChange).map((title) => (
                                <tr key={title.id} >
                                    <td onClick={() => postHandle(title.id, title.title, title.post, title.wordCount, title.editorId, title.editorPrice, title.authorId, title.authorPrice, title.totalPrice, title.brand, title.brandId, title.titleWordCount, title.status, title.brief, title.keyWords)} className="pointer">{title.title}</td>
                                    <td>{title.brand}</td>
                                    <td>{title.author}</td>
                                    <td>{title.editor}</td>
                                    <td>{title.serviceType}</td>
                                    <td> {title.titleWordCount} / {title.wordCount}</td>
                                    <td>{title.startDate}</td>
                                    <td>{title.endDate}</td>
                                    {/* <td>{title.status}</td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>}
            <div className="bg-secondary text-center rounded p-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <h6 className="mb-0">Marka Bilgileri</h6>
                </div>
                <div className="table-responsive">

                    <table className="table text-start align-middle table-bordered table-hover mb-0">
                        <thead>
                            <tr className="text-white">
                                <th scope="col">Marka Adı</th>
                                <th scope="col">Aylık Adet</th>
                                <th scope="col">Anahtar Kelimeler</th>
                                <th scope="col">Hizmet Başlangıç Tarihi</th>
                                <th scope="col">Hizmet Bitiiş  Tarihi</th>
                                <th scope="col">Detaylar</th>
                            </tr>
                        </thead>
                        {brands.map(brand => (
                            <tbody key={brand.id}>
                                <tr>
                                    <td>{brand.brandName}</td>
                                    <td> {brand.contentPcs}</td>
                                    <td> {brand.keyWords} </td>
                                    <td> {brand.serviceStart} </td>
                                    <td>{brand.serviceEnd}</td>
                                    <td><Link to={"/brand-details-admin"} onClick={() => localStorage.setItem("brandId", brand.brandId)}><Button className="btn btn-sm btn-primary" color='danger'>Detaylar</Button></Link></td>
                                </tr>
                            </tbody>
                        ))}
                    </table>

                </div>
            </div>
            <div className="container-fluid pt-4 px-4">
            </div>
        </div >
    )
}

export default AdminHome