import { createContext, useState, useSelector, useContext, useRef, useEffect } from "react";
import db from "../firebase";
import { UserAuth } from "./AuthContext";

const SiteContext = createContext()

export const useSiteContext = () => useContext(SiteContext);


const SiteProvider = ({ children }) => {

  const [patSearch, setPatSearch] = useState('');
  const [menuToggle, setMenuToggle] = useState(false);
  const [updateHandler_, setUpdateHandler_] = useState(false);
  const [months, setMonths] = useState([{ name: "Ocak", number: 1 }, { name: "Şubat", number: 2 }, { name: "Mart", number: 3 }, { name: "Nisan", number: 4 }, { name: "Mayıs", number: 5 }, { name: "Haziran", number: 6 }, { name: "Temmuz", number: 7 }, { name: "Ağustos", number: 8 }, { name: "Eylül", number: 9 }, { name: "Ekim", number: 10 }, { name: "Kasım", number: 11 }, { name: "Aralık", number: 12 }]);




  const toggleHandle = () => {
    setMenuToggle(current => !current)
  }


  const ay = new Date().getMonth();

  const dogruAy = "0" + (new Date().getMonth() + 1);


  let currentMonth;
  switch (ay) {
    case 0:
      currentMonth = "Ocak";
      break;
    case 1:
      currentMonth = "Şubat";
      break;
    case 2:
      currentMonth = "Mart";
      break;
    case 3:
      currentMonth = "Nisan";
      break;
    case 4:
      currentMonth = "Mayıs";
      break;
    case 5:
      currentMonth = "Haziran";
      break;
    case 6:
      currentMonth = "Temmuz";
      break;
    case 7:
      currentMonth = "Ağustos";
      break;
    case 8:
      currentMonth = "Eylül";
      break;
    case 9:
      currentMonth = "Ekim";
      break;
    case 10:
      currentMonth = "Kasım";
      break;
    case 11:
      currentMonth = "Aralık";
      break;

  }


  const data = {
    patSearch,
    setPatSearch,
    menuToggle,
    setMenuToggle,
    toggleHandle,
    currentMonth,
    ay,
    dogruAy,
    updateHandler_,
    setUpdateHandler_,
    months,
    setMonths
  }

  return (
    <SiteContext.Provider value={data} >
      {children}
    </SiteContext.Provider>
  );
}


export default SiteProvider;