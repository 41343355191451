import React, { useEffect, useState } from 'react'
import UserLogo from '../images/user.jpg';
import Logo from '../images/butik-icerik-logo-beyaz.png';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import db, { auth } from '../firebase';
import { useSiteContext } from '../context/SiteContext';

const Navbar = () => {
    const [isActive, setIsActive] = useState();

    const { menuToggle } = useSiteContext();

    const [profile, setProfile] = useState();

    useEffect(() => {
        db.collection("profile/").where("uid", "==", JSON.parse(localStorage.getItem("userIds"))?.uid).onSnapshot(snapshot => (
            setProfile(snapshot.docs.map(doc => (
                {
                    uid: doc.data().uid,
                    email: doc.data().email,
                    userLevel: doc.data().userLevel,
                    name: doc.data().name,
                    phone: doc.data().phone,
                    brandUserId: doc.data().brandUserId,
                    services: doc.data().services
                }
            )))
        ));
    }, [])




    return (
        <div className={classNames({
            "sidebar pe-4 pb-3": true,
            "open": menuToggle,
        })} >
            <nav className="navbar bg-secondary navbar-dark">
                <div className="navbar-nav w-100">
                    <Link to={"/"} className={classNames({
                        "nav-item nav-link pb-3 pt-3": true,
                        "active": window.location.pathname === "/"
                    })} onClick={() => setIsActive("a")}>Ana Sayfa</Link>
                    {profile && profile[0]?.userLevel === "2" ? <> <Link to={"/brands"} className={classNames({
                        "nav-item nav-link pb-3 pt-3": true,
                        "active": window.location.pathname === "/brands"
                    })} onClick={() => setIsActive("b")}>Markalar</Link>
                        <Link to={"/authors"} className={classNames({
                            "nav-item nav-link pb-3 pt-3": true,
                            "active": window.location.pathname === "/authors"
                        })} onClick={() => setIsActive("e")}>Yazarlar</Link> </> : <></>}
                    {
                        profile && profile[0].userLevel === "2" ? <><Link to={"/editors"} className={classNames({
                            "nav-item nav-link pb-3 pt-3": true,
                            "active": window.location.pathname === "/editors"
                        })} onClick={() => setIsActive("c")} >Editörler</Link>
                            <Link to={"/signup"} className={classNames({
                                "nav-item nav-link pb-3 pt-3": true,
                                "active": window.location.pathname === "/signup"
                            })} onClick={() => setIsActive("d")}>Kullanıcı Ekle</Link>
                        </> : <></>}
                    {
                        profile && profile[0].services[0] === "1" ? <> <Link to={"/animation-detail"} className={classNames({
                            "nav-item nav-link pb-3 pt-3": true,
                            "active": window.location.pathname === "/animation-detail"
                        })} onClick={() => setIsActive("ani")}>Animasyon Video Hizmetleri</Link></> : null
                    }
                    {
                        profile && profile[0].services[1] === "1" ? <> <Link to={"/content-detail"} className={classNames({
                            "nav-item nav-link pb-3 pt-3": true,
                            "active": window.location.pathname === "/content-detail"
                        })} onClick={() => setIsActive("i")}>İçerik Hizmetleri</Link></> : null
                    }
                    {
                        profile && profile[0].services[2] === "1" ? <> <Link to={"/seo-detail"} className={classNames({
                            "nav-item nav-link pb-3 pt-3": true,
                            "active": window.location.pathname === "/seo-detail"
                        })} onClick={() => setIsActive("seo")}>SEO Hizmetleri</Link></> : null
                    }
                    {
                        profile && profile[0].services[3] === "1" ? <> <Link to={"/social-detail"} className={classNames({
                            "nav-item nav-link pb-3 pt-3": true,
                            "active": window.location.pathname === "/social-detail"
                        })} onClick={() => setIsActive("s")}>Sosyal Medya Hizmetleri</Link></> : null
                    }
                    {
                        profile && profile[0].services[4] === "1" ? <> <Link to={"/web-detail"} className={classNames({
                            "nav-item nav-link pb-3 pt-3": true,
                            "active": window.location.pathname === "/web-detail"
                        })} onClick={() => setIsActive("w")}>Web & Mobil Projeleri</Link></> : null
                    }

                </div>
            </nav>

            <div className="d-flex position-absolute align-items-center ms-4 mb-4 bottom-0">
                <div className="position-relative">
                    <img className="rounded-circle" src={UserLogo} alt="" />
                    <div
                        className="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1">
                    </div>
                </div>
                <div className="ms-3">
                    <h6 className="mb-0"> {profile && profile[0].name} </h6> {/* user.name */}
                    {/* {
                        profile && profile[0].userLevel === "0" ? <span>Yazar</span> : profile && profile[0].userLevel === "1" ? <span>Editör</span> : profile && profile[0].userLevel === "3" ? <span>Marka</span> : <span>Admin</span>
                    } */}
                    <Link onClick={() => auth.signOut()}>Çıkış Yap</Link>
                </div>
            </div>
        </div>
    )
}

export default Navbar