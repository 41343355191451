import React from 'react'
import { useState } from 'react';
import { Col, Row, Label, Input } from 'reactstrap';
import db from '../../firebase';


const ContentPrice = ({ brandId, brandNewPrice, editorNewPrice, setUpdateModal }) => {
    const [updateBrandPrice, setUpdateBrandPrice] = useState();
    const [updateEditorPrice, setUpdateEditorPrice] = useState();

    const updateHandle = () => {
        console.log("brandPrice= " + updateBrandPrice + " editorPrice= " + updateEditorPrice)
        db.collection("brands").doc(`${brandId}`).update({
            brandPrice: updateBrandPrice ? updateBrandPrice : brandNewPrice,
            editorPrice: updateEditorPrice ? updateEditorPrice : editorNewPrice
        });
        setUpdateModal(current => !current);

    }
    return (
        <>
            <Row className='mb-3'>
                <Col className='align-self-center text-center'>
                    <h3 className='mb-0'>Marka Bilgilerini Güncelle</h3>
                    <hr />
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col xl="4" className='align-self-center text-start'>
                    <Label className='mb-0'>İçerik Adet Fiyatı (Marka):</Label>
                </Col>
                <Col xl="8">
                    <Input type="number" placeholder={brandNewPrice} value={updateBrandPrice} onChange={(e) => setUpdateBrandPrice(e.target.value)} ></Input>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col xl="4" className='align-self-center text-start'>
                    <Label className='mb-0'>İçerik Adet Fiyatı (Editör):</Label>
                </Col>
                <Col xl="8">
                    <Input type="number" placeholder={editorNewPrice} value={updateEditorPrice} onChange={(e) => setUpdateEditorPrice(e.target.value)} ></Input>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col xl="6" className='text-center'>
                    <button type="button" className="btn btn-primary m-2" onClick={updateHandle}>Güncelle</button>
                </Col>
            </Row>
        </>
    )
}

export default ContentPrice