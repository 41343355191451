import React, { useEffect, useState } from 'react'
import { useSiteContext } from '../../context/SiteContext';
import { Button, Col, Row, Label, Input } from 'reactstrap';
import db, { storage } from '../../firebase';
import { deleteObject, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import toast from 'react-hot-toast';
import Loader from '../Loader';
import { MdArrowBack, MdMenuBook } from 'react-icons/md'
import Posts from '../Posts'




const SEODetailAdmin = () => {
    const { currentMonth, dogruAy, months } = useSiteContext()

    const [resivedPopup, setResivedPopup] = useState(false);
    const [resivedNote, setResivedNote] = useState();
    const [tph_, setTph_] = useState();
    const [brandUserDetail, setBrandUserDetail] = useState();
    const [brandUserTitleDetail, setBrandUserTitleDetail] = useState();
    const [selectedMonth, setSelectedMonth] = useState(dogruAy);
    const [revisedNotes, setRevisedNotes] = useState();
    const [revisedId, setRevisedId] = useState();
    const [editWork, setEditWork] = useState();
    const [showBrief, setShowBrief] = useState(false);
    const [brief_, setBrief_] = useState();
    const [editWorkShow, setEditWorkShow] = useState(false);
    const [sortedList, setSortedList] = useState();

    const [editInput, setEditInput] = useState({ name: "", type: "", content: "", link: "", status: "", date: "" });


    useEffect(() => {
        db.collection("seo").where("brandId", "==", localStorage.getItem("brandId")).onSnapshot(snapshot => (
            setBrandUserTitleDetail(snapshot.docs.map(doc => (
                {
                    id: doc.id,
                    cloudLink: doc.data().cloudLink,
                    category: doc.data().category,
                    status: doc.data().status,
                    startDate: doc.data().startDate,
                    postPcs: doc.data().postPcs,
                    publishDate: doc.data().publishDate,
                    postContent: doc.data().postContent,
                    brandId: doc.data().brandId,
                    postName: doc.data().postName,
                    postType: doc.data().postType,
                    notes: doc.data().notes,
                    brief: doc.data().brief


                }
            )
            ))
        ))
        if (localStorage.getItem("brandId")) {
            db.collection("brands/").where("brandId", "==", localStorage.getItem("brandId")).onSnapshot(snapshot => (
                setBrandUserDetail(snapshot.docs.map(doc => (
                    {
                        id: doc.id,
                        brandName: doc.data().brandName,
                        brandId: doc.data().brandId,
                        contentPcs: doc.data().contentPcs,
                        serviceStart: doc.data().serviceStart,
                        serviceEnd: doc.data().serviceEnd,
                        keyWords: doc.data().keyWords,
                        imgURL: doc.data().imgURL, brandPrice: doc.data().brandPrice,
                        editorPrice: doc.data().editorPrice,
                        brandSummary: doc.data().brandSummary
                    }
                )
                ))
            ))
        }

    }, []);
    useEffect(() => {
        const sortedChars = brandUserTitleDetail && [...brandUserTitleDetail].sort((a, b) => {
            return b.postName.localeCompare(a.postName);

        });
        setSortedList(sortedChars);
    }, [brandUserTitleDetail]);

    const toggleHandle_ = () => {
        setTph_(current => !current)
    }

    const revisedHandler = (x) => {
        setResivedPopup(current => !current)
        setRevisedId(x)
    }
    const resivedModal = () => {
        setResivedPopup(current => !current)
    }

    const showRevised = (x, y) => {
        setResivedPopup(current => !current)
        setRevisedNotes(x)
        setRevisedId(y)
    }

    const acceptHandler = (x) => {
        db.collection("social").doc(x).update({
            status: "Yayında"
        });
    }

    const sendRevised = () => {
        setResivedPopup(current => !current)
        console.log("Revize Notu: " + resivedNote);
        console.log("id: " + revisedId);
        db.collection("seo").doc(revisedId).update({
            notes: resivedNote,
            status: "Revize"
        });
    }

    const editWorkHandler = (v) => {
        setEditWorkShow(current => !current);
        db.collection("seo").where("id", "==", v).onSnapshot(snapshot => (
            setEditWork(snapshot.docs.map(doc => (
                {
                    id: doc.id,
                    cloudLink: doc.data().cloudLink,
                    category: doc.data().category,
                    status: doc.data().status,
                    startDate: doc.data().startDate,
                    postPcs: doc.data().postPcs,
                    publishDate: doc.data().publishDate,
                    postContent: doc.data().postContent,
                    brandId: doc.data().brandId,
                    postName: doc.data().postName,
                    postType: doc.data().postType,
                    notes: doc.data().notes,
                    brief: doc.data().brief


                }
            )
            ))
        ))
    }

    const saveEditedWorkHandler = () => {
        setEditWorkShow(current => !current);
        db.collection("seo").doc(editWork[0]?.id).update({
            postName: editInput.name ? editInput.name : editWork[0]?.postName,
            postType: editInput.type ? editInput.type : editWork[0]?.postType,
            postContent: editInput.content ? editInput.content : editWork[0]?.postContent,
            cloudLink: editInput.link ? editInput.link : editWork[0]?.cloudLink,
            startDate: editInput.date ? editInput.date : editWork[0]?.startDate,
            status: editInput.date ? editInput.date : editWork[0]?.status,

        });
        setEditInput("");
    }

    const showBriefHandler = (v, y) => {
        setShowBrief(current => !current);
        setBrief_({ name: v, text: y })
    }

    return (
        <>
            <div className="container-fluid pt-4 px-4">
                <div className="row g-2 mb-3">
                    <div className="col-sm-6 col-xl-6">
                        <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                            <div className="ms-3">
                                <h6 className="mb-0"> {brandUserDetail && brandUserDetail[0].brandName} -  SEO Hizmetleri</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {resivedPopup && <div className="popup-box">
                    <div className="box">
                        <span className="close-icon" onClick={resivedModal}>x</span>
                        <Row> <h6> Revize Notları </h6> </Row>
                        {revisedNotes && revisedNotes}
                    </div>
                </div>}
                {showBrief && <div className="popup-box">
                    <div className="box">
                        <span className="close-icon" onClick={showBriefHandler}>x</span>
                        <h6> {brief_.name} </h6>
                        <p style={{ textAlign: "justify" }}> {brief_.text}  </p>
                    </div>
                </div>}

                <div className="bg-secondary text-center rounded p-4">
                    <Row className='mb-3'>
                        <Col lg="2" className="d-flex align-items-center justify-content-between align-self-center">
                            <select className="form-select" aria-label="Default select example" onChange={(e) => setSelectedMonth(e.target.value)}>
                                <option value={dogruAy.split("")[1] < 10 ? "0" + dogruAy.split("")[1] : dogruAy.split("")[1]} > {currentMonth} </option>
                                {months.map(month => <>
                                    <option value={month.number < 10 ? "0" + month.number : month.number} > {month.name} </option>
                                </>)}
                            </select>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-between align-self-center text-start"><h6 className='mt-2'> Ayı Çalışmaları</h6></Col>
                    </Row>
                    <div className="table-responsive">
                        <table className="table text-start align-middle table-bordered table-hover mb-0">
                            <thead>
                                <tr className="text-white">
                                    <th scope="col">Başlık</th>
                                    <th scope="col">Tür </th>
                                    <th scope="col">Açıklama</th>
                                    <th scope="col">Link</th>
                                    <th scope="col">Başlangıç Tarihi</th>
                                    <th scope="col">Durum</th>
                                    <th scope="col">Düzenle</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!editWorkShow && <> {sortedList?.filter(title => title.startDate.split(".")[1] === selectedMonth).map((title) => (
                                    <tr key={title.id}>
                                        <td>{title.postName} <br /> <span style={{ fontSize: "12px" }}>Çalışma Brif:</span>  <a onClick={() => showBriefHandler(title.postName, title.brief)} className='cursor-pointer' > <MdMenuBook color='#EB1616' /> </a> </td>
                                        <td>{title.postType} </td>
                                        <td>{title.postContent}</td>
                                        <td><a href={title.cloudLink} target='blank'>Görüntüle</a></td>
                                        <td>{title.startDate}</td>
                                        <td> {title.status === "Revize" ? <a onClick={() => showRevised(title.notes, title.id)} className="btn btn-sm btn-primary" color='danger'> {title.status} </a> : <>{title.status}</>}   </td>
                                        <td><a onClick={() => editWorkHandler(title.id)} className="btn btn-sm btn-primary" color='danger'>Düzenle</a></td>
                                    </tr>
                                ))} </>}
                                {editWorkShow &&
                                    <tr key={editWork && editWork[0]?.id} >
                                        <td> <Input type='text' placeholder={editWork && editWork[0]?.postName} value={editInput.name} onChange={(e) => setEditInput({ name: e.target.value })} /></td>
                                        <td> <Input type='text' placeholder={editWork && editWork[0]?.postType} value={editInput.type} onChange={(e) => setEditInput({ type: (e.target.value) })} /></td>
                                        <td className='w-50' > <Input type='textarea' placeholder={editWork && editWork[0]?.postContent} value={editInput.content} onChange={(e) => setEditInput({ content: (e.target.value) })} /></td>
                                        <td> <Input type='text' placeholder={editWork && editWork[0]?.cloudLink} value={editInput.link} onChange={(e) => setEditInput({ link: (e.target.value) })} /></td>
                                        <td> <Input type='text' placeholder={editWork && editWork[0]?.startDate} value={editInput.date} onChange={(e) => setEditInput({ date: (e.target.value) })} /></td>
                                        <td> <Input type='text' placeholder={editWork && editWork[0]?.status} value={editInput.status} onChange={(e) => setEditInput({ status: (e.target.value) })} /></td>
                                        <td> <a onClick={() => saveEditedWorkHandler()} className="btn btn-sm btn-primary" color='danger'>Kaydet</a></td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SEODetailAdmin