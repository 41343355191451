import React from 'react'
import { useSiteContext } from '../context/SiteContext'
import Editors from './Editors';
import BrandsDetail from './BrandsDetail';
import EditorDetail from './EditorDetail';
import Notifications from './Notifications';
import classNames from 'classnames';
import { Route, Routes } from 'react-router-dom';
import SignUp from './SignUp';
import Login from './Login';
import Brands from './Brands';
import Posts from './Posts';
import SignUpEditor from './SignUpEditor';
import Authors from './Authors';
import SignUpAuthor from './SignUpAuthor';
import AuthorDetail from './AuthorDetail';
import AuthorDetailAdmin from './AuthorDetailAdmin';
import BrandsDetailAdmin from './BrandsDetailAdmin';
import EditorDetailAdmin from './EditorDetailAdmin';
import Home from './Home';
import AnimationDetailAdmin from './AdminDetailPages/AnimationDetailAdmin';
import SocialDetailAdmin from './AdminDetailPages/SocialDetailAdmin';
import SEODetailAdmin from './AdminDetailPages/SEODetailAdmin';
import WebDetailAdmin from './AdminDetailPages/WebDetailAdmin';
import ContentDetailAdmin from './AdminDetailPages/ContentDetailAdmin';
import Finance from './Finance';
import SocialDetail from './DetailPages/SocialDetail';
import AnimationDetail from './DetailPages/AnimationDetail';
import WebDetail from './DetailPages/WebDetail';
import SEODetail from './DetailPages/SEODetail';
import ContentDetail from './DetailPages/ContentDetail';

const Content = () => {

    const { menuToggle } = useSiteContext();

    return (
        <div className={classNames({
            "content": true,
            "open": menuToggle,
        })}>
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signupeditor" element={<SignUpEditor />} />
                <Route path="/signupauthor" element={<SignUpAuthor />} />
                <Route path="/" element={<Home />} />
                <Route path="/brands" element={<Brands />} />
                <Route path="/brand-details-admin" element={<BrandsDetailAdmin />} />
                <Route path="/authors" element={<Authors />} />
                <Route path="/editors" element={<Editors />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path='/author-details' element={<AuthorDetail />} />
                <Route path='/editor-details' element={<EditorDetail />} />
                <Route path="/brand-details" element={<BrandsDetail />} />
                <Route path="/social-detail" element={<SocialDetail />} />
                <Route path="/animation-detail" element={<AnimationDetail />} />
                <Route path="/web-detail" element={<WebDetail />} />
                <Route path="/seo-detail" element={<SEODetail />} />
                <Route path="/content-detail" element={<ContentDetail />} />
                <Route path="/content-detail-admin" element={<ContentDetailAdmin />} />
                <Route path="/seo-detail-admin" element={<SEODetailAdmin />} />
                <Route path="/web-detail-admin" element={<WebDetailAdmin />} />
                <Route path="/animation-detail-admin" element={<AnimationDetailAdmin />} />
                <Route path='/editor-detail-admin' element={<EditorDetailAdmin />} />
                <Route path='/author-detail-admin' element={<AuthorDetailAdmin />} />
                <Route path='/social-detail-admin' element={<SocialDetailAdmin />} />
                <Route path='/posts' element={<Posts />} />
                <Route path='/finance' element={<Finance />} />
            </Routes>
        </div>
    )
}

export default Content