import React from 'react'
import { useState } from 'react';
import { Col, Row, Label, Input } from 'reactstrap';
import db from '../../firebase';


const BrandSummary = ({ brandId, brandNewSummary, setUpdateModal }) => {

    const [updateBrandSummary, setUpdateBrandSummary] = useState();


    const updateHandle = () => {
        db.collection("brands").doc(`${brandId}`).update({
            brandSummary: updateBrandSummary ? updateBrandSummary : brandNewSummary
        });
        setUpdateModal(current => !current);
    }
    return (
        <>
            <Row className='mb-3'>
                <Col className='align-self-center text-center'>
                    <h3 className='mb-0'>Marka Bilgilerini Güncelle</h3>
                    <hr />
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col xl="4" className='align-self-center text-start'>
                    <Label className='mb-0'>Marka Özeti:</Label>
                </Col>
                <Col xl="8">
                    <Input type="textarea" placeholder={brandNewSummary} value={updateBrandSummary} onChange={(e) => setUpdateBrandSummary(e.target.value)} ></Input>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col xl="6" className='text-center'>
                    <button type="button" className="btn btn-primary m-2" onClick={updateHandle}>Güncelle</button>
                </Col>
            </Row>
        </>
    )
}

export default BrandSummary