import React, { useEffect, useState } from 'react'
import AdminHome from './AdminHome';
import AuthorDetail from './AuthorDetail';
import BrandsDetail from './BrandsDetail';
import EditorDetail from './EditorDetail';
import db from '../firebase';

const Home = () => {

    const [profile, setProfile] = useState();

    useEffect(() => {
        db.collection("profile/").where("uid", "==", JSON.parse(localStorage.getItem("userIds"))?.uid).onSnapshot(snapshot => (
            setProfile(snapshot.docs.map(doc => (
                {
                    uid: doc.data().uid,
                    email: doc.data().email,
                    userLevel: doc.data().userLevel,
                    name: doc.data().name,
                    phone: doc.data().phone,
                    brandUserId: doc.data().brandUserId,
                    services: doc.data().services
                }
            )))
        ));
    }, [])


    return (
        <>
            {profile && profile[0]?.userLevel === "3" ? <BrandsDetail /> : <></>}
            {profile && profile[0]?.userLevel === "2" ? <AdminHome /> : <></>}
            {profile && profile[0]?.userLevel === "1" ? <EditorDetail /> : <></>}
            {profile && profile[0]?.userLevel === "0" ? <AuthorDetail /> : <></>}

        </>
    )
}

export default Home