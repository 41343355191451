import React, { useEffect, useState } from 'react'
import brandsLogo from '../images/butik-icerik-logo-beyaz.png'
import { useSiteContext } from '../context/SiteContext';
import { Button, Col, Row, Label, Input } from 'reactstrap';
import db, { storage } from '../firebase';
import { deleteObject, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import toast from 'react-hot-toast';
import Loader from './Loader';
import { MdArrowBack, MdCloudUpload, MdDelete } from 'react-icons/md'
import { nanoid } from 'nanoid'
import Posts from './Posts';
import { Link } from 'react-router-dom';

const EditorDetail = () => {

    const { profile, currentMonth, dogruAy, months } = useSiteContext()

    const [tph_, setTph_] = useState(false);
    const [editorDetails, setEditorDetails] = useState();
    const [newTitleDetails, setNewTitleDetails] = useState();
    const [titleStart, setTitleStart] = useState();
    const [titleEnd, setTitleEnd] = useState();
    const [statusChange, setStatusChange] = useState("Editör");
    const [selectedMonth, setSelectedMonth] = useState(dogruAy);
    const [selectedContent, setSelectedContent] = useState();



    const addTitlePopup = () => {
        setTph_(current => !current)
    }

    const toggleHandle_ = () => {
        setTph_(current => !current)
    }

    const postHandle = (id, title, post, wordCount, editorId, editorPrice, authorId, authorPrice, totalPrice, brand, brandId, titleWordCount, status, brief, keyWords) => {
        setSelectedContent(
            { id: id, title: title, post: post, wordCount: wordCount, editorId: editorId, editorPrice: editorPrice, authorId: authorId, authorPrice: authorPrice, totalPrice: totalPrice, brand: brand, brandId: brandId, titleWordCount: titleWordCount, status: status, brief: brief, keyWords: keyWords }
        )
        setTph_(current => !current)
    }


    const year = titleStart?.split("-")[0]
    const month = titleStart?.split("-")[1]
    const day = titleStart?.split("-")[2]
    const year_ = titleEnd?.split("-")[0]
    const month_ = titleEnd?.split("-")[1]
    const day_ = titleEnd?.split("-")[2]
    const titleStartDate_ = day + "." + month + "." + year;
    const titleEndDate_ = day_ + "." + month_ + "." + year_;

    useEffect(() => {
        db.collection("titles").where("editorId", "==", JSON.parse(localStorage.getItem("userIds"))?.uid).onSnapshot(snapshot => (
            setNewTitleDetails(snapshot.docs.map(doc => (
                {
                    id: doc.id,
                    title: doc.data().title,
                    serviceType: doc.data().serviceType,
                    wordCount: doc.data().wordCount,
                    titleWordCount: doc.data().titleWordCount,
                    author: doc.data().author,
                    authorId: doc.data().authorId,
                    editor: doc.data().editor,
                    editorId: doc.data().editorId,
                    editorPrice: doc.data().editorPrice,
                    status: doc.data().status,
                    startDate: doc.data().startDate,
                    endDate: doc.data().endDate,
                    sortEndDate: doc.data().sortEndDate,
                    post: doc.data().post,
                    revised: doc.data().revised,
                    brief: doc.data().brief,
                    language: doc.data().language,
                    keyWords: doc.data().keyWords,
                    brand: doc.data().brand,
                    brandId: doc.data().brandId,

                }
            )))
        ))
        db.collection("profile").where("uid", "==", JSON.parse(localStorage.getItem("userIds"))?.uid).onSnapshot(snapshot => (
            setEditorDetails(snapshot.docs.map(doc => (
                {
                    totalPrice: doc.data().totalPrice
                }
            )))
        ))
    }, []);




    return (
        <>

            <div className="container-fluid pt-4 px-4">
                <div className="row g-4 mb-3">
                    <div className="col-sm-6 col-xl-3">
                        <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                            <div className="ms-3">
                                <p className="mb-2">Yazar Adı</p>
                                <h6 className="mb-0">{newTitleDetails && newTitleDetails[0]?.editor}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                        <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                            <div className="ms-3">
                                <p className="mb-2">Toplam İçerik Sayısı</p>
                                <h6 className="mb-0"> {newTitleDetails && newTitleDetails?.length} </h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                        <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                            <div className="ms-3">
                                <p className="mb-2">Aylık Kazanç</p>
                                <h6 className="mb-0"> {editorDetails && editorDetails[0]?.totalPrice} </h6>
                            </div>
                        </div>
                    </div>
                </div>
                {tph_ && <>
                    <div className="bg-secondary text-center rounded p-4 my-5">
                        <Row>

                            <MdArrowBack style={{ fontSize: 34, width: "5%", cursor: "pointer" }} onClick={toggleHandle_} />
                            <Row>
                                <Posts content={selectedContent} />
                            </Row>

                        </Row>
                    </div></>}

                {!tph_ && <div className="bg-secondary text-center rounded p-4">

                    <Row className='mb-3'>
                        <Col lg="2" className="d-flex align-items-center justify-content-between align-self-center">
                            <select className="form-select" aria-label="Default select example" onChange={(e) => setSelectedMonth("0" + e.target.value)}>
                                <option value={dogruAy.split("")[1]} > {currentMonth} </option>
                                {months.map(month => <>
                                    <option value={month.number} > {month.name} </option>
                                </>)}
                            </select>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-between align-self-center text-start"><h6 className='mt-2'> Ayı Başlıkları</h6></Col>
                    </Row>
                    <Row className='flex justify-content-start mb-2'>
                        <Col xl="auto"> <Button onClick={() => setStatusChange("Yazar")} > Yazar </Button> </Col>
                        <Col xl="auto"> <Button onClick={() => setStatusChange("Editör")}> Editör </Button> </Col>
                        <Col xl="auto"> <Button onClick={() => setStatusChange("Onay")}> Onay </Button> </Col>
                        <Col xl="auto"> <Button onClick={() => setStatusChange("Marka")}> Marka </Button> </Col>
                        <Col xl="auto"> <Button onClick={() => setStatusChange("Yayın")}> Yayın </Button> </Col>
                    </Row>

                    <div className="table-responsive">
                        <table className="table text-start align-middle table-bordered table-hover mb-0">
                            <thead>
                                <tr className="text-white">
                                    <th scope="col">Başlık Adı</th>
                                    <th scope="col">Yazar</th>
                                    <th scope="col">Editör</th>
                                    <th scope="col">İçerik Türü</th>
                                    <th scope="col">Kelime Sayısı</th>
                                    <th scope="col">Başlangıç Tarihi</th>
                                    <th scope="col">Bitiş Tarihi</th>
                                    {/* <th scope="col">Durum</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {newTitleDetails?.filter(title => title.sortEndDate.split("-")[1] === selectedMonth).filter(title => title.status === statusChange).map((title) => (
                                    <tr key={title.id} >
                                        <td onClick={title.status === "Editör" ? () => postHandle(title.id, title.title, title.post, title.wordCount, title.editorId, title.editorPrice, title.authorId, title.authorPrice, title.totalPrice, title.brand, title.brandId, title.titleWordCount, title.status, title.brief, title.keyWords) : null} className="pointer">{title.title}</td>
                                        <td>{title.author}</td>
                                        <td>{title.editor}</td>
                                        <td>{title.serviceType}</td>
                                        <td> {title.titleWordCount} / {title.wordCount}</td>
                                        <td>{title.startDate}</td>
                                        <td>{title.endDate}</td>
                                        {/* <td>{title.status}</td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>}
            </div>
        </>
    )
}

export default EditorDetail