import React, { useState, useEffect } from 'react'
import { useSiteContext } from '../context/SiteContext';
import db from '../firebase';
import { Button } from 'reactstrap';

const Finance = () => {

    const { profile } = useSiteContext();
    const [totalPrice, setTotalPrice] = useState();
    const [authors, setAuthors] = useState([]);
    const [editors, setEditors] = useState([]);
    const [authorsTotal, setAuthorsTotal] = useState();
    const [editorsHandler, setEditorsHandler] = useState();



    useEffect(() => {
        db.collection("profile/").where("userLevel", "==", "0").onSnapshot(snapshot => (
            setAuthors(snapshot.docs.map(doc => (
                {
                    email: doc.data().email,
                    uid: doc.data().uid,
                    name: doc.data().name,
                    phone: doc.data().phone,
                    totalPrice: doc.data().totalPrice
                }
            )))
        ))
        db.collection("profile/").where("userLevel", "==", "1").onSnapshot(snapshot => (
            setEditors(snapshot.docs.map(doc => (
                {
                    email: doc.data().email,
                    uid: doc.data().uid,
                    name: doc.data().name,
                    phone: doc.data().phone,
                    totalPrice: doc.data().totalPrice,
                }
            )))
        ))
    }, []);

    useEffect(() => {
        if (authors.length > 0) {
            let authorsTotal = 0;
            for (let i = 0; i < authors.length; i++) {
                authorsTotal = authorsTotal + parseInt(authors[i].totalPrice);
                setAuthorsTotal(authorsTotal)
            }
        }
        if (editors.length > 0) {

        }
    })

    const calcHandle = () => {
        db.collection("profile").where("userLevel", "==", "1").onSnapshot(snapshot => (
            setEditorsHandler(snapshot.docs.map(doc => (
                {
                    uid: doc.data().uid,
                    totalPrice: doc.data().totalPrice
                }
            )))
        ))

        console.log("TIK" + editorsHandler)
        editorsHandler.filter(price => price.totalPrice > 20).map(price => (
            db.collection("profile").doc("profile_" + price.uid).update({
                totalPrice: 30,
            })
        ))
        console.log("ŞARAP İÇİCEZ")

    }

    return (
        <div>
            <p>Editör Toplam Ödeme: {authorsTotal}  </p>
            <p>Yazar Toplam Ödeme: </p>
            <Button onClick={calcHandle}> Editör Hesapla</Button>
        </div>

    )
}

export default Finance