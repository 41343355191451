import React from 'react'
import { useState } from 'react';
import { Col, Row, Label, Input } from 'reactstrap';
import db from '../../firebase';


const HomeContentPrice = ({ price, contentName, setUpdateModal_, uname }) => {
    const [updatePrice, setUpdatePrice] = useState();

    const updateHandle = () => {
        db.collection("price").doc(`${uname}`).update({
            price: updatePrice ? updatePrice : price
        });
        setUpdateModal_(current => !current);
    }
    return (
        <>
            <Row className='mb-3'>
                <Col className='align-self-center text-center'>
                    <h3 className='mb-0'>İçerik Ücretini Güncelle</h3>
                    <hr />
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col xl="4" className='align-self-center text-start'>
                    <Label className='mb-0'> {contentName && contentName} Ücreti </Label>
                </Col>
                <Col xl="8">
                    <Input type="number" placeholder={price} value={updatePrice} onChange={(e) => setUpdatePrice(e.target.value)} ></Input>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col xl="6" className='text-center'>
                    <button type="button" className="btn btn-primary m-2" onClick={updateHandle}>Güncelle</button>
                </Col>
            </Row>
        </>
    )
}

export default HomeContentPrice