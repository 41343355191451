import React, { useEffect, useState } from 'react'
import { useSiteContext } from '../../context/SiteContext';
import { Button, Col, Row, Label, Input } from 'reactstrap';
import db, { storage } from '../../firebase';
import { deleteObject, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import toast from 'react-hot-toast';
import Loader from '../Loader';
import { MdArrowBack, MdMenuBook } from 'react-icons/md'
import Posts from '../Posts'




const ContentDetail = () => {
    const { profile, currentMonth, dogruAy, months } = useSiteContext()

    const [resivedPopup, setResivedPopup] = useState(false);
    const [resivedNote, setResivedNote] = useState();
    const [tph_, setTph_] = useState();
    const [brandUserDetail, setBrandUserDetail] = useState();
    const [brandUserTitleDetail, setBrandUserTitleDetail] = useState();
    const [selectedMonth, setSelectedMonth] = useState(dogruAy);
    const [selectedContent, setSelectedContent] = useState();
    const [revisedNotes, setRevisedNotes] = useState();
    const [revisedId, setRevisedId] = useState();
    const [showBrief, setShowBrief] = useState(false);
    const [sortedList, setSortedList] = useState();
    const [brief_, setBrief_] = useState();


    useEffect(() => {

        db.collection("social").where("brandId", "==", JSON.parse(localStorage.getItem("userIds"))?.uid).onSnapshot(snapshot => (
            setBrandUserTitleDetail(snapshot.docs.map(doc => (
                {
                    id: doc.id,
                    cloudLink: doc.data().cloudLink,
                    category: doc.data().category,
                    status: doc.data().status,
                    startDate: doc.data().startDate,
                    postPcs: doc.data().postPcs,
                    publishDate: doc.data().publishDate,
                    postContent: doc.data().postContent,
                    brandId: doc.data().brandId,
                    postName: doc.data().postName,
                    postType: doc.data().postType,
                    notes: doc.data().notes,
                    brief: doc.data().brief


                }
            )
            ))
        ))


        db.collection("brands/").where("brandId", "==", JSON.parse(localStorage.getItem("userIds"))?.uid).onSnapshot(snapshot => (
            setBrandUserDetail(snapshot.docs.map(doc => (
                {
                    id: doc.id,
                    brandName: doc.data().brandName,
                    brandId: doc.data().brandId,
                    contentPcs: doc.data().contentPcs,
                    serviceStart: doc.data().serviceStart,
                    serviceEnd: doc.data().serviceEnd,
                    keyWords: doc.data().keyWords,
                    imgURL: doc.data().imgURL, brandPrice: doc.data().brandPrice,
                    editorPrice: doc.data().editorPrice,
                    brandSummary: doc.data().brandSummary
                }
            )
            ))
        ))
    }, [profile]);
    useEffect(() => {
        const sortedChars = brandUserTitleDetail && [...brandUserTitleDetail].sort((a, b) => {
            return b.postName.localeCompare(a.postName);

        });
        setSortedList(sortedChars);
    }, [brandUserTitleDetail]);

    const toggleHandle_ = () => {
        setTph_(current => !current)
    }

    const revisedHandler = (x) => {
        setResivedPopup(current => !current)
        setRevisedId(x)
    }
    const resivedModal = () => {
        setResivedPopup(current => !current)
    }

    const showRevised = (x, y) => {
        setResivedPopup(current => !current)
        setRevisedNotes(x)
        setRevisedId(y)
        console.log(x)
    }

    const acceptHandler = (x) => {
        db.collection("social").doc(x).update({
            status: "Yayında"
        });
    }

    const sendRevised = () => {
        setResivedPopup(current => !current)
        console.log("Revize Notu: " + resivedNote);
        console.log("id: " + revisedId);
        db.collection("social").doc(revisedId).update({
            notes: resivedNote,
            status: "Revize"
        });
    }

    const showBriefHandler = (v, y) => {
        setShowBrief(current => !current);
        setBrief_({ name: v, text: y })
    }


    return (
        <>
            {/* {resivedPopup && <div className="popup-box">
                <div className="box">
                    <span className="close-icon" onClick={resivedModal}>x</span>
                    {revisedNotes === "" ? <>
                        <Row className="product-name mb-4">Revize notlarınızı buraya girebilirsiniz.</Row>
                        <Row className='mb-4'>
                            <Col >
                                <Input type="textarea" value={resivedNote} onChange={(e) => setResivedNote(e.target.value)}></Input>
                            </Col>
                        </Row>
                        <Row className=' float-end'>
                            <Col>
                                <Button
                                    color="success"
                                    className="mr-1"
                                    outline
                                    onClick={sendRevised}
                                >
                                    Revizeye Gönder
                                </Button>
                                <Button
                                    color="danger"
                                    outline
                                    onClick={resivedModal}
                                >
                                    İptal
                                </Button>
                            </Col>
                        </Row>
                    </> : revisedNotes === undefined ? null
                        : <>
                            <Row> <h6> Revize Notları </h6> </Row>
                            {revisedNotes && revisedNotes} </>}
                </div>
            </div>}
            <div className="container-fluid pt-4 px-4">
                <div className="row g-4">
                    <div className="col-sm-6 col-xl-3">
                        <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                            <div className="ms-3">
                                <p className="mb-2" >Marka</p>
                                <h6 className="mb-0"> {brandUserDetail && brandUserDetail[0]?.brandName} </h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                        <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                            <div className="ms-3">
                                <p className="mb-2" >Aylık İçerik Sayısı</p>
                                <h6 className="mb-0"> {brandUserDetail && brandUserDetail[0]?.contentPcs} </h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                        <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                            <div className="ms-3">
                                <p className="mb-2">Hizmet Başlangıç </p>
                                <h6 className="mb-0"> {brandUserDetail && brandUserDetail[0]?.serviceStart} / {brandUserDetail && brandUserDetail[0]?.serviceEnd}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                        <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                            <div className="ms-3">
                                <p className="mb-2">İçerik Adet Fiyatı</p>
                                <h6> {brandUserDetail && brandUserDetail[0]?.brandPrice}₺ + KDV </h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-4 mb-5">
                    <div className="col-sm-6 col-xl-6">
                        <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                            <div className="ms-3">
                                <p className="mb-2">Marka Özeti</p>
                                <h6 className="mb-0"> {brandUserDetail && brandUserDetail[0]?.brandSummary} </h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-6">
                        <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                            <div className="ms-3">
                                <p className="mb-2">Anahtar Kelimeler</p>
                                <h6 className="mb-0"> {brandUserDetail && brandUserDetail[0]?.keyWords} </h6>
                            </div>
                        </div>
                    </div>
                </div>
                {tph_ && <>
                    <Row>
                        <div className="bg-secondary text-left rounded p-4 my-5">

                            <MdArrowBack style={{ fontSize: 34, width: "5%", cursor: "pointer" }} onClick={toggleHandle_} />
                            <Row>
                                <Posts content={selectedContent} />
                            </Row>
                        </div>
                    </Row>
                </>}
                {!tph_ && <>
                    <div className="bg-secondary text-center rounded p-4">
                        <Row className='mb-3'>
                            <Col lg="2" className="d-flex align-items-center justify-content-between align-self-center">
                                <select className="form-select" aria-label="Default select example" onChange={(e) => setSelectedMonth("0" + e.target.value)}>
                                    <option value={dogruAy.split("")[1]} > {currentMonth} </option>
                                    {months.map(month => <>
                                        <option value={month.number} > {month.name} </option>
                                    </>)}
                                </select>
                            </Col>
                            <Col className="d-flex align-items-center justify-content-between align-self-center text-start"><h6 className='mt-2'> Ayı Çalışmaları</h6></Col>
                        </Row>
                        <div className="table-responsive">
                            <table className="table text-start align-middle table-bordered table-hover mb-0">
                                <thead>
                                    <tr className="text-white">
                                        <th scope="col">Başlık</th>
                                        <th scope="col">Kategori / Tür </th>
                                        <th scope="col">Açıklama</th>
                                        <th scope="col">Link</th>
                                        <th scope="col">Başlangıç Tarihi</th>
                                        <th scope="col">Durum</th>
                                        <th scope="col">Onay</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {brandUserTitleDetail?.filter(title => title.startDate.split(".")[1] === selectedMonth).map((title) => (
                                        <tr key={title.id} >
                                            <td> {title.postName} </td>
                                            <td>{title.category} / {title.postType} </td>
                                            <td>{title.postContent}</td>
                                            <td> <a href={title.cloudLink} target='blank' >Görüntüle</a> </td>
                                            <td>{title.startDate}</td>
                                            <td>{title.status}</td>
                                            {title.status == "Hazırlanıyor" || title.status == "Yayında" ? <td></td>
                                                : title.status == "Revize" ? <td> <a className="btn btn-sm btn-primary" onClick={() => showRevised(title.notes)} > Revizede </a>  </td>
                                                    : <td> <a onClick={() => acceptHandler(title.id)} className="btn btn-sm btn-primary" color='danger'>Onay</a>  | <a onClick={() => showRevised(title.notes, title.id)} className="btn btn-sm btn-primary" color='danger'>Revize</a></td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>}
            </div> */}
            {resivedPopup && <div className="popup-box">
                <div className="box">
                    <span className="close-icon" onClick={resivedModal}>x</span>
                    {revisedNotes === "" || revisedNotes === undefined ? <>
                        <Row className="product-name mb-4">Revize notlarınızı buraya girebilirsiniz.</Row>
                        <Row className='mb-4'>
                            <Col >
                                <Input type="textarea" value={resivedNote} onChange={(e) => setResivedNote(e.target.value)}></Input>
                            </Col>
                        </Row>
                        <Row className=' float-end'>
                            <Col>
                                <Button
                                    color="success"
                                    className="mr-1"
                                    outline
                                    onClick={sendRevised}
                                >
                                    Revizeye Gönder
                                </Button>
                                <Button
                                    color="danger"
                                    outline
                                    onClick={resivedModal}
                                >
                                    İptal
                                </Button>
                            </Col>
                        </Row>
                    </> : revisedNotes === undefined ? null
                        : <>
                            <Row> <h6> Revize Notları </h6> </Row>
                            {revisedNotes && revisedNotes} </>}
                </div>
            </div>}
            <div className="container-fluid pt-4 px-4 ">
                <div className="row g-2 mb-3">
                    <div className="col-sm-6 col-xl-4">
                        <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                            <div className="d-flex">
                                <h6 className="mb-2"> {brandUserDetail && brandUserDetail[0].brandName} -  Sosyal Medya Hizmetlerim</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-4">
                        <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                            <div className="d-flex">
                                <p className="mb-0 mr-1">Aylık İçerik Sayısı:</p>
                                <h6 className="mt-1">{brandUserDetail && brandUserDetail[0]?.contentPcs}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-4">
                        <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4">
                            <div className="d-flex">
                                <p className="mb-0 mr-1">İçerik Adet Fiyatı:</p>
                                <h6 className="mt-1"> {brandUserDetail && brandUserDetail[0]?.brandPrice}₺ + KDV </h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row g-2 mb-3' >

                </div>



                {tph_ && <>
                    <Row>
                        <div className="bg-secondary text-left rounded p-4 my-5">

                            <MdArrowBack style={{ fontSize: 34, width: "5%", cursor: "pointer" }} onClick={toggleHandle_} />
                            <Row>
                                <Posts content={selectedContent} />
                            </Row>
                        </div>
                    </Row>
                </>}
                {showBrief && <div className="popup-box">
                    <div className="box">
                        <span className="close-icon" onClick={showBriefHandler}>x</span>
                        <h6> {brief_.name} </h6>
                        <p style={{ textAlign: "justify" }}> {brief_.text}  </p>
                    </div>
                </div>}

                <div className="bg-secondary text-center rounded p-4">
                    <Row className='mb-3'>
                        <Col lg="2" className="d-flex align-items-center justify-content-between align-self-center">
                            <select className="form-select" aria-label="Default select example" onChange={(e) => setSelectedMonth(e.target.value)}>
                                <option value={dogruAy.split("")[1] < 10 ? "0" + dogruAy.split("")[1] : dogruAy.split("")[1]} > {currentMonth} </option>
                                {months.map(month => <>
                                    <option value={month.number < 10 ? "0" + month.number : month.number} > {month.name} </option>
                                </>)}
                            </select>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-between align-self-center text-start"><h6 className='mt-2'> Ayı Çalışmaları</h6></Col>
                    </Row>
                    <div className="table-responsive">
                        <table className="table text-start align-middle table-bordered table-hover mb-0">
                            <thead>
                                <tr className="text-white">
                                    <th scope="col">Başlık</th>
                                    <th scope="col">Tür </th>
                                    <th scope="col">Açıklama</th>
                                    <th scope="col">Link</th>
                                    <th scope="col">Başlangıç Tarihi</th>
                                    <th scope="col">Durum</th>
                                    <th scope="col">Onay</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedList?.filter(title => title.startDate.split(".")[1] === selectedMonth).map((title) => (
                                    <tr key={title.id}>
                                        <td>{title.postName} <br /> <span style={{ fontSize: "12px" }}>Çalışma Brif:</span>  <a onClick={() => showBriefHandler(title.postName, title.brief)} className='cursor-pointer'> <MdMenuBook color='#EB1616' /> </a> </td>
                                        <td>{title.postType} </td>
                                        <td>{title.postContent}</td>
                                        <td><a href={title.cloudLink} target='blank'>Görüntüle</a></td>
                                        <td>{title.startDate}</td>
                                        <td>{title.status}</td>
                                        {title.status == "Hazırlanıyor" || title.status == "Yayında" ? <td></td>
                                            : title.status == "Revize" ? <td> <a className="btn btn-sm btn-primary" onClick={() => showRevised(title.notes)} > Revizede </a>  </td>
                                                : <td> <a onClick={() => acceptHandler(title.id)} className="btn btn-sm btn-primary" color='danger'>Onay</a>  | <a onClick={() => showRevised(title.notes, title.id)} className="btn btn-sm btn-primary" color='danger'>Revize</a></td>}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContentDetail