import React from 'react'
import { useState } from 'react';
import { Col, Row, Label, Input } from 'reactstrap';
import db from '../../firebase';


const KeyWords = ({ brandId, brandKeyWords, setUpdateModal }) => {

    const [updateKeyWords, setUpdateKeyWords] = useState();

    const updateHandle = () => {
        console.log("brandSummary= " + updateKeyWords)
        db.collection("brands").doc(`${brandId}`).update({
            keyWords: updateKeyWords ? updateKeyWords : brandKeyWords
        })
        setUpdateModal(current => !current);
    }
    return (
        <>
            <Row className='mb-3'>
                <Col className='align-self-center text-center'>
                    <h3 className='mb-0'>Marka Bilgilerini Güncelle</h3>
                    <hr />
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col xl="4" className='align-self-center text-start'>
                    <Label className='mb-0'>Anahta Kelimeler:</Label>
                </Col>
                <Col xl="8">
                    <Input type="textarea" placeholder={brandKeyWords} value={updateKeyWords} onChange={(e) => setUpdateKeyWords(e.target.value)} ></Input>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col xl="6" className='text-center'>
                    <button type="button" className="btn btn-primary m-2" onClick={updateHandle}>Güncelle</button>
                </Col>
            </Row>
        </>
    )
}

export default KeyWords