import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast';
import { Col, Row, InputGroup, Input, Label, Button } from 'reactstrap';
import { nanoid } from 'nanoid'
import db from '../firebase';
import { useSiteContext } from '../context/SiteContext';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Authors = () => {
    const { patSearch, setPatSearch } = useSiteContext()

    const [tph, setTph] = useState(false);
    const [authorIdNew, setAuthorIdNew] = useState();
    const [authors, setAuthors] = useState([]);


    useEffect(() => {

        db.collection("profile/").where("userLevel", "==", "0").onSnapshot(snapshot => (
            setAuthors(snapshot.docs.map(doc => (
                {
                    email: doc.data().email,
                    uid: doc.data().uid,
                    name: doc.data().name,
                    phone: doc.data().phone
                }
            )))
        ))

    }, []);

    const removeEditorFromDb = (id) => {
        setAuthorIdNew(id)
        setTph(current => !current)
    }

    const removeEditorFromDb_ = () => {
        db.collection("profile").doc(`profile_${authorIdNew}`).delete();
        setTph(current => !current)
    }
    const toggleHandle = () => {
        setTph(current => !current)
    }

    const navigate = useNavigate();

    const singUpHandle = () => {
        navigate("/signupauthor", { replace: true })
    }



    return (
        <>
            <Row xs="1" className="search-bar mt-5">
                <Col lg="6" className='align-self-center'>
                    <InputGroup>
                        <Input
                            placeholder="Ara"
                            type="search"
                            value={patSearch.toLowerCase()}
                            autoFocus="autoFocus"
                            onChange={(e) => setPatSearch(e.target.value)}
                            className="border border-primary"
                        />
                    </InputGroup>
                </Col>
                <Col lg="6" className='text-end'>
                    <button type="button" className="btn btn-primary m-2" onClick={singUpHandle}>Yeni Yazar <b>+</b></button>
                </Col>
            </Row>
            {tph && <div className="popup-box">
                <div className="box">
                    <span className="close-icon" onClick={toggleHandle}>x</span>
                    <Row className="product-name"> Yazarı silmek istediğinize emin misiniz?</Row>
                    <Row>
                        <Col>
                            <Button
                                color="success"
                                outline
                                className="float-end"
                                onClick={(e) => removeEditorFromDb_(authors.uid)}
                            >
                                Sil
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                color="danger"
                                outline
                                onClick={toggleHandle}
                            >
                                İptal
                            </Button>
                        </Col>
                    </Row>

                </div>
            </div>}
            <Row>
                {authors.filter((authors) => authors.email.toLowerCase().includes(patSearch)).map((authors, index) => (
                    <Col lg="4" md="6" sm="6" xs="6" key={authors.uid}>
                        <div className="bg-secondary rounded d-flex align-items-center justify-content-between p-4 mt-5">
                            <Link to={"/author-details-admin"} onClick={() => localStorage.setItem("authorId", authors.uid)}><i className="fa fa-chart-line fa-3x text-primary">{authors.name}</i></Link>
                            <div className="ms-3">
                                <h6 className="mb-2">{authors.email}</h6>
                                <h6 className="mb-2">{authors.phone}</h6>
                                <Button className='my-2' color='danger' onClick={() => removeEditorFromDb(authors.uid)} >Sil</Button>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default Authors